import React, { useState, useEffect, useCallback } from "react";
import moment from "moment-timezone";
import axios from "axios";

import Container from "react-bulma-components/lib/components/container";
import Columns from "react-bulma-components/lib/components/columns";
import Heading from "react-bulma-components/lib/components/heading";
import CommunityNavbar from "../components/communityNavbar";
import Button from "react-bulma-components/lib/components/button";
import { Editor } from "@tinymce/tinymce-react";
import { Coffee, Edit2, X } from "react-feather";
import SideBar from "../components/sidebar";
import PostCard from "../components/postCard";
import {
  Control,
  Label,
  Field,
  Input,
} from "react-bulma-components/lib/components/form";

import { API_URL } from "../config.json"; // Import API Url
import { toast } from "react-toastify";
import Spinner from "components/spinner";

export default function WellWishes() {
  const token = localStorage.getItem("token");
  const [showForm, setShowForm] = useState(false);
  const [wellwishes, setWellWishes] = useState([]);

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [validForm, setValidForm] = useState(false);

  var containerStyle = {
    margin: "5% 5%",
    maxWidth: "100%",
  };

  var formContainerStyle = {
    padding: "5%",
    border: "1px solid hsl(0, 0%, 86%)",
    borderRadius: "10px",
    marginTop: "20px",
  };

  var noteStyle = {
    fontSize: "0.75rem",
    fontStyle: "italic",
    textAlign: "center",
    padding: "20px",
    backgroundColor: "hsl(0, 0%, 96%)",
    borderRadius: "10px",
  };

  useEffect(() => {
    const formValues = [subject, message];
    const notValidForm = formValues.some((formVal) => {
      return formVal === "";
    });
    setValidForm(notValidForm);
  }, [subject, message]);
  const [loading, setLoading] = useState(true);

  const handleSubmit = useCallback(() => {
    // Convert time to UTC before passing into API
    let dateTime = moment.utc().format("MM/DD/YYYY [at] h:mm a");

    const param = {
      subject: subject,
      message: message,
      date_time: dateTime,
      user: localStorage.getItem("email"),
      community: localStorage.getItem("community-name"),
      author_name: "",
    };

    axios
      .post(`${API_URL}/add-well-wish/`, param, {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(
        (_) => {
          window.location.reload();
        },
        (error) => {
          toast.error("Opps, something went wrong!");
          console.log(error);
        }
      );
  }, [subject, message, token]);

  useEffect(() => {
    axios
      .get(`${API_URL}/well-wishes/`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
        params: {
          name: localStorage.getItem("community-name"),
          zipcode: localStorage.getItem("community-zipcode"),
          is_closed: localStorage.getItem("community-is-closed"),
          community_id: localStorage.getItem("community-id"),
        },
      })
      .then(
        (response) => {
          setLoading(false)
          setWellWishes(response.data);
        },
        (error) => {
          toast.error("Opps, something went wrong!");
          console.log(error);
        }
      );
  }, [token]);

  return (
    <div>
      <CommunityNavbar />
      <Container style={containerStyle}>
        <Columns ismultiline={"true"}>
          <Columns.Column size={3}>
            <SideBar />
          </Columns.Column>
          <Columns.Column size={9}>
            <Columns>
              <Columns.Column size={9}>
                <Heading size={4}>Well Wishes</Heading>
              </Columns.Column>
              <Columns.Column size={3}>
                <Button
                  onClick={() => setShowForm(!showForm)}
                  color="primary"
                  className="is-fullwidth"
                >
                  <div>
                    {showForm ? (
                      <X size={12} style={{ marginRight: "5px" }} />
                    ) : (
                      <Edit2 size={12} style={{ marginRight: "5px" }} />
                    )}

                    {showForm ? "Hide Message Form" : "Leave a Message"}
                  </div>
                </Button>
              </Columns.Column>
            </Columns>
            <div>
              <p>
                Well Wishes is the place to drop a line to say hello, post a
                prayer or let the family you are helping know that you’re
                thinking about them.
              </p>
            </div>

            {showForm && (
              <div>
                <div style={formContainerStyle}>
                  <Field>
                    <Label>
                      Subject<span style={{ color: "#F83D34" }}>*</span>
                    </Label>
                    <Control>
                      <Input
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </Control>
                  </Field>
                  <Field>
                    <Label>
                      Message<span style={{ color: "#F83D34" }}>*</span>
                    </Label>
                    <Control>
                      <input
                        id="my-file"
                        type="file"
                        name="my-file"
                        style={{ display: "none" }}
                      />
                      <Editor
                        initialValue={message}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace wordcount visualblocks code fullscreen",
                            "insertdatetime media table contextmenu paste code",
                          ],
                          toolbar:
                            "insertfile undo redo | formatselect | bold italic underline backcolor | " +
                            "alignleft aligncenter alignright alignjustify | " +
                            "bullist numlist outdent indent | link image media | help",
                          file_browser_callback_types: "image",
                          file_picker_callback: function (
                            callback,
                            value,
                            meta
                          ) {
                            if (meta.filetype === "image") {
                              var input = document.getElementById("my-file");
                              input.click();
                              input.onchange = function () {
                                var file = input.files[0];
                                var reader = new FileReader();
                                reader.onload = function (e) {
                                  callback(e.target.result, {
                                    alt: file.name,
                                  });
                                };
                                reader.readAsDataURL(file);
                              };
                            }
                          },
                          paste_data_images: true,
                        }}
                        onEditorChange={(content, editor) =>
                          setMessage(content)
                        }
                      />
                    </Control>
                  </Field>
                </div>
                <br />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    color="primary"
                    disabled={validForm}
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}

            <br />
            <div>
              {wellwishes.length > 0 ? (
                wellwishes
                  .slice()
                  .reverse()
                  .map((a, index) => {
                    return (
                      <PostCard
                        key={index}
                        subject={a.subject}
                        message={a.message}
                        dateTime={a.date_time}
                        user={a.author_name}
                        id={a.id}
                        type="well-wish"
                      />
                    );
                  })
              ) : (
                loading ? <Spinner /> :
                <p style={noteStyle}>
                  <Coffee size={100} color="#E5E5E5" />
                  <br />
                  <br />
                  Sit tight! Nothing has been posted yet.
                </p>
              )}
            </div>
          </Columns.Column>
        </Columns>
      </Container>
    </div>
  );
}
