import React from 'react'
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";



const Spinner = (data) => {
    const override = css`
    display: block;
    margin: 0 auto;
  `;
  
  var message = data.message === null ? '' : data.message;

  console.log(message)

    return (
        <div>
         <GridLoader css={override} color={'#2C8595'} size={40} style={{ paddingBottom: '20px' }} />
         <center>
            <h3><b>{message}</b></h3>
            </center>   
        </div>
    )
}

export default Spinner;
