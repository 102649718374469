import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Menu from 'react-bulma-components/lib/components/menu'
import { Link as LinkIcon, MessageSquare, FileText } from 'react-feather'

import axios from 'axios'
import { API_URL } from '../config.json' // Import API Url
import { toast } from 'react-toastify'
import Spinner from './spinner'

export default function CustomSections() {
  const [sections, setSections] = useState([])
  const [ loading, setLoading ] = useState(true);

  var noteStyle = {
    fontSize: '0.75rem',
    fontStyle: 'italic',
    padding: '20px',
    backgroundColor: 'hsl(0, 0%, 96%)',
    borderRadius: '10px',
  }

  function getCustomSections() {
    if (localStorage.getItem(`communitySections`)) {
      setSections(JSON.parse(localStorage.getItem(`communitySections`)))
      setLoading(false);
    }
    else {
      axios
      .get(`${API_URL}/community-custom-sections/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`,
        },
        params: {
          name: localStorage.getItem('community-name'),
        },
      })
      .then(
        (response) => {
          setSections(response.data)
          localStorage.setItem(`communitySections`, JSON.stringify(response.data));
          setLoading(false)
        },
        (error) => {
          toast.error(error);
          console.log(error)
        }
      )
    }
    

  }

  useEffect(() => {
    getCustomSections();
  }, [])

  if (loading) {
    return <Spinner />
  }

  else {
    return (
      <div>
        <Menu>
          <Menu.List title="Custom Pages">
            {sections.length !== 0 ? (
              sections.map((section, key) =>
                section.type !== 'BUTTON' ? (
                  <Link
                    key = {key}
                    to={{
                      pathname: '/custom/' + section.name,
                      state: {
                        section: section.id,
                      },
                    }}
                  >
                    <div className='has-theme-color' style={{ display: 'flex', alignItems: 'center' }}>
                      {section.type === 'DP' ? (
                        <MessageSquare
                          size={14}
                          style={{ marginRight: '10px' }}
                        />
                      ) : (
                        <FileText size={14} style={{ marginRight: '10px' }} />
                      )}
                      <p className='sidebar'>{section.name}</p>
                    </div>
                  </Link>
                ) : (
                  <a href={section.link} target='_blank' rel='noopener noreferrer' key={key}>
                    <p className='sidebar has-theme-color'>
                      <LinkIcon size={14} style={{ marginRight: '10px' }} />
                      {section.name}
                    </p>
                  </a>
                )
              )
            ) : (
              <p style={noteStyle}>No custom pages has been created.</p>
            )}
          </Menu.List>
          <hr />
        </Menu>
      </div>
    )
  }


}
