import React, { useState, useEffect, useCallback } from "react"
import countryList from 'react-select-country-list'
import zipcodeTimezone from 'zipcode-to-timezone'
import axios from 'axios'
import { toast } from "react-toastify"; // Notifications

import Container from 'react-bulma-components/lib/components/container';
import Columns from 'react-bulma-components/lib/components/columns'
import Heading from 'react-bulma-components/lib/components/heading'
import Button from 'react-bulma-components/lib/components/button';
import {
    Field,
    Input,
    Label,
    Select
} from 'react-bulma-components/lib/components/form'
import SideNavAccount from '../components/sideNavAccount'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { API_URL } from '../config.json'; // Import API Url


export default function AccountSettings() {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone1, setPhone1] = useState('')
    const [phone1Type, setPhone1Type] = useState('')
    const [phone2, setPhone2] = useState('')
    const [phone2Type, setPhone2Type] = useState('')
    const [addressLine1, setAddressLine1] = useState('')
    const [addressLine2, setAddressLine2] = useState('')
    const [zipcode, setZipcode]  = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const [pk, setPk] = useState('')

    var containerStyle = {
        margin: '3% auto',
        maxWidth: '80%',
        padding: '4rem',
    }

    // Get current user's information
    useEffect(() => {
        if (localStorage.getItem('token')) {
            axios
                .get(`${API_URL}/current_user/`, {
                    headers: {
                        Authorization: `JWT ${localStorage.getItem('token')}`,
                    },
                })
                .then(
                    (response) => {
                        setFirstName(response.data.first_name)
                        setLastName(response.data.last_name)
                        setEmail(response.data.email)

                        setPhone1(response.data.phone_number_1)
                        setPhone1Type(response.data.phone_number_1_type)
                        setPhone2(response.data.phone_number_2)
                        setPhone2Type(response.data.phone_number_2_type)

                        setAddressLine1(response.data.address_line_1)
                        setAddressLine2(response.data.address_line_2)
                        setCity(response.data.city)
                        setCountry(response.data.country)
                        setState(response.data.state)
                        setZipcode(response.data.zipcode)
                        setPk(response.data.id)
                        localStorage.setItem('user-id', response.data.id)
                    },
                    (error) => {
                       toast.error(error);
                        console.log(error)
                    }
                )
        }
    }, [])

    const editInformation = useCallback(() => {
        var url = `${API_URL}/edit-user/${pk}/`

        // Change the timezone in local storage base on new zipcode (if it changed)
        var tzn = zipcodeTimezone.lookup(zipcode)
        var tz = tzn != null ? tzn: "America/Los_Angeles"
        localStorage.setItem('timezone', tz)

        // Edit user's information. First_name, last_name, and email are required.
        const param = {
            'first_name': firstName,
            'last_name': lastName,
            'email': email,
            'phone_number_1': phone1,
            'phone_number_1_type': phone1Type,
            'phone_number_2': phone2,
            'phone_number_2_type': phone2Type,
            'address_line_1': addressLine1,
            'address_line_2': addressLine2,
            'city': city,
            'state': state,
            'zipcode': zipcode,
            'country': country,
        }
        axios.patch(url, param, {
            headers: {
                'Authorization': `JWT ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        }).then(_ => {
            toast.success("You have successfully updated your information!");
        }).catch(function(error) {
            toast.error("Opps! Something went wrong. Please try again.")
            if(error.response) {
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log('Error', error.message)
            }
        })
    },
    [
        firstName,
        lastName,
        email,
        phone1,
        phone1Type,
        phone2,
        phone2Type,
        addressLine1,
        addressLine2,
        city,
        state,
        zipcode,
        country,
        pk
    ])


    return (
        <Container style={containerStyle}>
            <Columns>
                <Columns.Column style={{ maxWidth: '30%' }}>
                    <SideNavAccount />
                </Columns.Column>
                <Columns.Column>

                    {/* Account Information  */}
                    <Heading size={4} style={{ marginBottom: '3%' }}>Account Information</Heading>
                    <Columns>
                        <Columns.Column>
                            <Field>
                                <Label>First Name<span style={{ color: '#F83D34' }}>*</span></Label>
                                <Input
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    placeholder={firstName}
                                />
                            </Field>
                        </Columns.Column>
                        <Columns.Column>
                            <Field>
                                <Label>Last Name<span style={{ color: '#F83D34' }}>*</span></Label>
                                <Input
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    placeholder={lastName}
                                />
                            </Field>
                        </Columns.Column>
                        </Columns>
                        <Field>
                            <Label>Email<span style={{ color: '#F83D34' }}>*</span></Label>
                            <Input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={email}
                            />
                        </Field>
                        <Columns>
                            <Columns.Column>
                                <Field>
                                    <Label>Primary Phone Number<span style={{ color: '#F83D34' }}>*</span></Label>
                                    <PhoneInput
                                        placeholder="Enter primary phone number"
                                        value={phone1}
                                        onChange={setPhone1}
                                    />
                                </Field>
                            </Columns.Column>
                            <Columns.Column>
                                <Field>
                                    <Label>Primary Phone Number Type<span style={{ color: '#F83D34' }}>*</span></Label>
                                    <Select onChange={(e) => setPhone1Type(e.target.value)} value={phone1Type}>
                                        <option>Mobile</option>
                                        <option>Home</option>
                                        <option>Work</option>
                                    </Select>
                                </Field>
                            </Columns.Column>
                        </Columns>
                        <Columns>
                            <Columns.Column>
                                <Field>
                                    <Label>Secondary Phone Number</Label>
                                    <PhoneInput
                                        placeholder="Enter secondary phone number"
                                        value={phone2}
                                        onChange={setPhone2}
                                    />
                                </Field>
                            </Columns.Column>
                            <Columns.Column>
                                <Field>
                                    <Label>Secondary Phone Number Type</Label>
                                    <Select onChange={(e) => setPhone2Type(e.target.value)} value={phone2Type}>
                                        <option></option>
                                        <option>Mobile</option>
                                        <option>Home</option>
                                        <option>Work</option>
                                    </Select>
                                </Field>
                            </Columns.Column>
                        </Columns>
                        <Columns>
                            <Columns.Column>
                                <Field>
                                    <Label>Address Line 1<span style={{ color: '#F83D34' }}>*</span></Label>
                                    <Input
                                        value={addressLine1}
                                        onChange={(e) => setAddressLine1(e.target.value)}
                                        placeholder={addressLine1}
                                    />
                                </Field>
                            </Columns.Column>
                            <Columns.Column>
                                <Field>
                                    <Label>Address Line 2</Label>
                                    <Input
                                        value={addressLine2}
                                        onChange={(e) => setAddressLine2(e.target.value)}
                                        placeholder={addressLine2}
                                    />
                                </Field>
                            </Columns.Column>
                        </Columns>
                        <Columns>
                            <Columns.Column>
                                <Field>
                                    <Label>City<span style={{ color: '#F83D34' }}>*</span></Label>
                                    <Input
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        placeholder={city}
                                    />
                                </Field>
                            </Columns.Column>
                            <Columns.Column>
                                <Field>
                                    <Label>State<span style={{ color: '#F83D34' }}>*</span></Label>
                                    <Input
                                        value={state}
                                        onChange={(e) => setState(e.target.value)}
                                        placeholder={state}
                                    />
                                </Field>
                            </Columns.Column>
                            <Columns.Column>
                                <Field>
                                    <Label>Zipcode<span style={{ color: '#F83D34' }}>*</span></Label>
                                    <Input
                                        value={zipcode}
                                        onChange={(e) => setZipcode(e.target.value)}
                                        placeholder={zipcode}
                                    />
                                </Field>
                            </Columns.Column>
                        </Columns>
                        <Field>
                            <Label>Country<span style={{ color: '#F83D34' }}>*</span></Label>
                            <Select onChange={(e) => setCountry(e.target.value)} value={country}>
                                {countryList()
                                    .getLabels()
                                    .map((c) => (
                                        <option key={c} style={{ position: 'static' }} value={c}>
                                            {c}
                                        </option>
                                ))}
                            </Select>
                        </Field>
                    <Button
                        color="primary"
                        fullwidth={true}
                        style={{maxWidth: '40%', marginTop: '8%'}}
                        onClick={() => editInformation()}
                    >
                        SAVE CHANGES
                    </Button>
                </Columns.Column>
            </Columns>
        </Container>
    );
}
