import React, { useState } from 'react'
import Box from 'react-bulma-components/lib/components/box'
import { Link } from 'react-router-dom'

import Menu from 'react-bulma-components/lib/components/menu'
import CustomSections from './customSections'
import {
  Layers,
  Calendar,
  AlertCircle,
  UserPlus,
  Users,
  Paperclip,
  Mail,
  Send,
  Edit,
  Star,
  Clock
} from 'react-feather'

import Permissions from './permissions'


const SideBar = () => {
  const [role] = useState(localStorage.getItem('user-role'))

  return (
    <div>
      <Menu color='white'>

          <Box>
            {role === Permissions.is_community_admin ?
              <div>
                <CustomSections />
                <Menu.List title='Quick access'>
                  <Link to='/create-custom-section'>
                    <div className='sidebar'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Star size={12} style={{ marginRight: '10px' }} />{' '}
                        <p>Create Custom Section</p>
                      </div>
                    </div>
                  </Link>

                  <Link to='/custom-sections'>
                    <div className='sidebar'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Layers size={12} style={{ marginRight: '10px' }} />{' '}
                        <p>Manage Custom Sections</p>
                      </div>
                    </div>
                  </Link>
                  <hr />
                  <Link to='/create-new-activity'>
                    <div className='sidebar'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Calendar size={12} style={{ marginRight: '10px' }} />
                        <p>Create New Activity</p>
                      </div>
                    </div>
                  </Link>

                  <Link to='/manage-activities'>
                    <div className='sidebar'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Layers size={12} style={{ marginRight: '10px' }} />
                        <p>Manage Existing Activities</p>
                      </div>
                    </div>
                  </Link>

                  <Link to='/activity-report'>
                    <div className='sidebar'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Paperclip size={12} style={{ marginRight: '10px' }} />
                        <p>Activity Report</p>
                      </div>
                    </div>
                  </Link>
                  <hr />

                  <Link to='/community-people'>
                    <div className='sidebar'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Users size={12} style={{ marginRight: '10px' }} />{' '}
                        <p>View & Update Member Information</p>
                      </div>
                    </div>
                  </Link>

                  <Link to='/add-people'>
                    <div className='sidebar'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <UserPlus size={12} style={{ marginRight: '10px' }} />
                        <p>Invite New Members</p>
                      </div>
                    </div>
                  </Link>

                  <Link to='/join-requests'>
                    <div className='sidebar'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AlertCircle size={12} style={{ marginRight: '10px' }} />
                        <p>Review Join Requests</p>
                      </div>
                    </div>
                  </Link>

                  <Link to='/pending-invites'>
                    <div className='sidebar'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Clock size={12} style={{ marginRight: '10px' }} />
                        <p>Review Pending Invites</p>
                      </div>
                    </div>
                  </Link>

                  <hr />

                  <Link to='/create-announcement'>
                    <div className='sidebar'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Send size={12} style={{ marginRight: '10px' }} />{' '}
                        <p>Add Announcement</p>
                      </div>
                    </div>
                  </Link>

                  <Link to='/email-members'>
                    <div className='sidebar'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Mail size={12} style={{ marginRight: '10px' }} />{' '}
                        <p>Email Members</p>
                      </div>
                    </div>
                  </Link>

                  <Link to='/edit-community'>
                  <div className='sidebar'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Edit size={12} style={{ marginRight: '10px' }} />{' '}
                      <p>Edit Community Information</p>
                    </div>
                  </div>
                </Link>
                </Menu.List>
              </div>
                :
              <div>
                <CustomSections />
                <Menu.List title='Quick access'>
                  <Link to='/my-activities'>
                    <div className='sidebar'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Calendar size={12} style={{ marginRight: '10px' }} />{' '}
                        <p>My Tasks</p>
                      </div>
                    </div>
                  </Link>
                </Menu.List>
              </div>
            }
          </Box>
      </Menu>
    </div>
  )
}

export default SideBar
