import React, { useState, useCallback, useEffect } from 'react'

import Container from 'react-bulma-components/lib/components/container'
import Heading from 'react-bulma-components/lib/components/heading'
import { Link } from 'react-router-dom'
import axios from 'axios'

import {
  Textarea,
  Control,
  Label,
  Field,
  Input,
} from 'react-bulma-components/lib/components/form'
import Columns from 'react-bulma-components/lib/components/columns'
import Button from 'react-bulma-components/lib/components/button'
import MultiSelect from '@khanacademy/react-multi-select'
import CommunityNavbar from '../components/communityNavbar'

import { API_URL } from '../config.json' // Import API Url
import { toast } from 'react-toastify'


export default function EmailMembers() {
  var containerStyle = {
    margin: '5% auto',
    maxWidth: '800px',
    maxHeight: '1000px',
    padding: '4rem',
    border: '0.1rem solid #E5E5E5',
    borderRadius: '1rem',
  }

  const [firstName, setFirstName] = useState('') // User sending the email
  const [lastName, setLastName] = useState('') // User sending the email
  const [subject, setSubject] = useState('')
  const [content, setContent] = useState('')
  const [members, setMembers] = useState([])
  const [selectedMembers, setSelectedMembers] = useState([])

  useEffect(() => {
    if (localStorage.getItem('token')) {
      axios.get(`${API_URL}/current_user/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => {
          setFirstName(response.data.first_name)
          setLastName(response.data.last_name)
        })
    }
  }, [])

  useEffect(() => {
    axios.get(`${API_URL}/community-people/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        params: JSON.stringify({
          user: localStorage.getItem('email'),
          community: localStorage.getItem('community-name'),
        }),
      })
      .then(
        (response) => {
          const options = response.data.people.map((item) => ({
            label: `${item['first_name']} ${item['last_name']}`,
            value: item['email'],
          }))
          setMembers(options)
        },
        (error) => {
          console.log(error)
        }
      )
  }, [])

  const sendEmail = useCallback((toEmails) => {
    const param = {
      to_emails: toEmails,
      subject: subject,
      content: content,
    }
    axios.post(`${API_URL}/send-email/`, param, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${localStorage.getItem('token')}`,
      },
    }).then(_ => {
      toast.success('Your email has been sent successfully!')
    }).catch(function(error) {
      toast.error('Opps, something went wrong! Please try again.')
      if(error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
      } else if (error.request) {
          console.log(error.request)
      } else {
          console.log('Error', error.message)
      }
    })
  }, [subject, content])

  return (
    <div>
      <CommunityNavbar />
      <Container style={containerStyle}>
        <Heading size={4}>Email Members</Heading>
        <strong>Send from:</strong> {localStorage.getItem('email')}
        <Field>
          <Label>Send to:</Label>
          <Control>
            <MultiSelect
              valueRenderer={(selectedMembers) => (
                <span width='100%'>
                  Selected {selectedMembers.length} users{' '}
                </span>
              )}
              options={members}
              selected={selectedMembers}
              onSelectedChanged={(selected) => setSelectedMembers(selected)}
            />
          </Control>
        </Field>
        <br />
        <strong>Subject:</strong>
        <Input
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          placeholder='Enter subject of email message.'
        />
        <br />
        <br />
        <strong>Message:</strong>
        <Textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder='Enter body of email message.'
        ></Textarea>
        <Columns style={{ marginTop: '5%' }}>
          <Columns.Column>
            <Link to='/community-people'>
              <Button
                className='is-primary is-inverted'
                style={{
                  marginBottom: '1rem',
                  boxShadow: '1px 1px 3px 2px rgba(0,0,0,0.1)',
                }}
                fullwidth={true}
              >
                Cancel
              </Button>
            </Link>
          </Columns.Column>
          <Columns.Column>
            <Button
              color='primary'
              style={{
                marginBottom: '1rem',
                boxShadow: '1px 1px 3px 2px rgba(0,0,0,0.1)',
              }}
              fullwidth={true}
              onClick={() =>
                sendEmail(
                  selectedMembers,
                )
              }
            >
              Send Email
            </Button>
          </Columns.Column>
        </Columns>
      </Container>
    </div>
  )
}
