import React, { useState, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'

import Container from 'react-bulma-components/lib/components/container'
import Columns from 'react-bulma-components/lib/components/columns'
import Heading from 'react-bulma-components/lib/components/heading'
import Modal from 'react-bulma-components/lib/components/modal'
import Section from 'react-bulma-components/lib/components/section'
import {
    Input,
    Control,
    Field,
    Label,
    Textarea,
    InputFile,
    Select
} from 'react-bulma-components/lib/components/form'
import Image from 'react-bulma-components/lib/components/image';
import Icon from 'react-bulma-components/lib/components/icon';
import Button from 'react-bulma-components/lib/components/button';

import SideBar from '../components/sidebar'
import CommunityNavbar from '../components/communityNavbar'
import CheckboxField from '../components/checkboxfield'

import { API_URL } from '../config.json' // Import API Url
import { toast } from 'react-toastify'

export default function CommunityEdit() {
    var containerStyle = {
        margin: '5% 5%',
        maxWidth: '100%',
    }

    let history = useHistory()

    const [communityName, setCommunityName] = useState('')
    const [communityDescription, setCommunityDescription] = useState('')
    const [communityZipcode, setCommunityZipcode] = useState('')
    const [homePageHighlight, setHomePageHighlight] = useState('')
    const [showLeaders, setShowLeaders] = useState(true)
    const [isClosed, setIsClosed] = useState(true)
    const pk = localStorage.getItem('community-id')

    // Homepage image
    const [photoFile, setPhotoFile] = useState('')
    const [photoURL, setPhotoURL] = useState('')

    const [showRemoveModal, setShowRemoveModel] = useState(false)

    useEffect(() => {
        axios
            .get(`${API_URL}/one-community/`, {
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                },
                params: {
                    pk: localStorage.getItem('community-id')
                },
            })
            .then(
                (response) => {
                    setCommunityName(response.data[0].name)
                    setCommunityDescription(response.data[0].description)
                    setCommunityZipcode(response.data[0].zipcode)
                    setHomePageHighlight(response.data[0].home_page_highlight)
                    setShowLeaders(response.data[0].display_leaders_on_home_page === 'true')
                    setPhotoFile(response.data[0].photo_file)
                    setIsClosed(response.data[0].is_closed)
                },
                (error) => {
                    console.log(error)
                }
            )
    }, [])

    const editCommunity = useCallback(() => {
        var url = `${API_URL}/edit-community/${pk}/`

        localStorage.setItem('community-name', communityName)
        localStorage.setItem('community-zipcode', communityZipcode)

        var formdata = new FormData();
        formdata.append('name', communityName)
        formdata.append('description', communityDescription)
        formdata.append('zipcode', communityZipcode)
        formdata.append('home_page_highlight', homePageHighlight)
        formdata.append('display_leaders_on_home_page', showLeaders.toString())
        formdata.append('is_closed', isClosed)
        if (photoURL)
            formdata.append('photo_file', photoFile)

        axios.put(url, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `JWT ${localStorage.getItem('token')}`
            }
        }).then((_) => {
            toast.success('You\'ve successfully updated the community information!')
        }).catch(function(error) {
            toast.error('Opps, something went wrong! Please try again.')
            if(error.response) {
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log('Error', error.message)
            }
        })
    },[ communityDescription, communityName, communityZipcode, homePageHighlight, isClosed, photoFile, photoURL, pk, showLeaders])

    const removeCommunity = useCallback(() => {
        axios.delete(`${API_URL}/edit-community/${pk}`, {
            headers: {
                'Authorization': `JWT ${localStorage.getItem('token')}`
            }
        }).then(_ => {
            history.push('/my-communities')
        }).catch(function(error) {
            toast.error('Opps, something went wrong! Please try again.')
            if(error.response) {
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log('Error', error.message)
            }
        })
    },[history, pk])

    return (
        <div>
            <CommunityNavbar />
            <Container style={containerStyle}>
                <Columns ismultiline={'true'}>
                    <Columns.Column size={3}>
                        <SideBar />
                    </Columns.Column>
                    <Columns.Column size={9}>
                        <Heading size={4}>Community Options</Heading>
                        <Field>
                            <Control>
                                <Label>
                                    Community Name
                                </Label>
                                <Input
                                    name='Community Name'
                                    value={communityName}
                                    onChange={(e) => setCommunityName(e.target.value)}
                                    placeholder={communityName}
                                />
                                <p style={{ fontSize: '80%' }} className='has-text-grey'>
                                    e.g., Helping Hands of Springfield, Helping Hands for Mary
                                </p>
                            </Control>
                        </Field>
                        <Field>
                            <Control>
                                <Label>
                                    About Our Community
                                </Label>
                                <Textarea
                                    name='Community Description'
                                    value={communityDescription}
                                    onChange={(e) => setCommunityDescription(e.target.value)}
                                    placeholder={communityDescription}
                                />
                                <p style={{ fontSize: '80%' }} className='has-text-grey'>
                                    This information is shown on your about page and home page
                                </p>
                            </Control>
                        </Field>
                        <Label>
                            Homepage Photo
                        </Label>
                        <div style={{ width: 320, marginBottom: '3%' }}>
                            <Field>
                                <Control>
                                    <InputFile
                                        value={photoFile}
                                        icon={<Icon icon='upload' />}
                                        onChange={(e) => {
                                            setPhotoURL(URL.createObjectURL(e.target.files[0]))
                                            setPhotoFile(e.target.files[0])
                                        }}
                                    />
                                </Control>
                            </Field>
                            <Image
                                src={photoURL ? photoURL : photoFile}
                            />
                        </div>
                        <Field style={{ maxWidth: '30%' }}>
                            <Control>
                                <Label>
                                    ZIP/Postal Code
                                </Label>
                                <Input
                                    name='Community Zipcode'
                                    value={communityZipcode}
                                    onChange={(e) => setCommunityZipcode(e.target.value)}
                                    placeholder={communityZipcode}
                                />
                            </Control>
                        </Field>

                        <Field>
                            <Label>
                                Community Visibility
                            </Label>
                            <CheckboxField
                                checked={isClosed}
                                text={'Only members that are approved can join.'}
                                onChange={(e) => setIsClosed(e.target.checked)}
                            />
                            <p style={{ fontSize: '80%' }} className='has-text-grey'>
                                If checked, you will need to go to the <Link to={'/join-requests'}>Join Requests</Link> page to approve members before they can access the community.
                            </p>
                        </Field>

                        <Heading size={4} style={{ marginTop: '5%' }}>Community Home Page</Heading>
                        <Field>
                            <Label>Home Page Highlight</Label>
                            <Control>
                                <Select name='Home Page Highlight' value={homePageHighlight} onChange={(e) => setHomePageHighlight(e.target.value)}>
                                    <option>Calendar</option>
                                    <option>Family Updates</option>
                                    <option>Ways to Help</option>
                                    <option>Message Board</option>
                                    <option>Photo Gallery</option>
                                    <option>Well Wishes</option>
                                </Select>
                            </Control>
                            <p style={{ fontSize: '80%' }} className='has-text-grey'>
                                This is what will show in the middle section of the community's home page.
                            </p>
                        </Field>
                        <Field>
                            <Label>
                                Show Coordinators on Home Page
                            </Label>
                            <CheckboxField checked={showLeaders} text={'Display Coordinator list on home page.'} onChange={(e) => setShowLeaders(e.target.checked)} />
                            <p style={{ fontSize: '80%' }} className='has-text-grey'>
                                If checked, Members will see the list of Coordinators under the sections list on your Community home page
                            </p>
                        </Field>
                        <Columns style={{ marginTop: '5%' }}>
                            <Columns.Column size={3}>
                                <Button
                                    style={{
                                        marginBottom: '1rem',
                                        boxShadow: '1px 1px 3px 2px rgba(0,0,0,0.1)',
                                    }}
                                    fullwidth={true}
                                    color='primary'
                                    onClick={() => editCommunity()}
                                >
                                    Save
                                </Button>
                            </Columns.Column>
                            <Columns.Column size={3}>
                                <Button
                                    style={{
                                        marginBottom: '1rem',
                                        boxShadow: '1px 1px 3px 2px rgba(0,0,0,0.1)',
                                    }}
                                    color='danger'
                                    fullwidth={true}
                                    onClick={() => setShowRemoveModel(true)}
                                >
                                    Shutdown Community
                                </Button>
                            </Columns.Column>
                        </Columns>
                    </Columns.Column>
                </Columns>
            </Container>

            <Modal
                show={showRemoveModal}
                onClose={() => setShowRemoveModel(false)}
                closeOnBlur={true}
            >
                <Modal.Card>
                    <Modal.Card.Head onClose={() => setShowRemoveModel(false)}>
                        <Modal.Card.Title>Remove "{communityName}" Community</Modal.Card.Title>
                    </Modal.Card.Head>
                        <Section style={{ backgroundColor: 'white' }}>
                            Are you sure you want to shutdown this community? You can't undo
                            this action.
                        </Section>
                    <Modal.Card.Foot
                        style={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        >
                        <Button onClick={() => setShowRemoveModel(false)}>
                            Cancel
                        </Button>
                        <Button color='primary' onClick={() => removeCommunity()}>
                            Shutdown Community
                        </Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </Modal>
        </div>
    )
}
