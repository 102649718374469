import React, { useState, useCallback } from 'react'
import { Trash2 } from 'react-feather'

import Modal from 'react-bulma-components/lib/components/modal'
import Heading from 'react-bulma-components/lib/components/heading'
import Button from 'react-bulma-components/lib/components/button'

import { API_URL } from '../config.json' // Import API Url
import axios from 'axios'
import { toast } from 'react-toastify'


const DeleteCustomSection = ({ id }) => {
  const [showDelete, setShowDelete] = useState(false)

  // Delete custom section
  const deleteSection = useCallback(() => {
    var url = `${API_URL}/delete-custom-section/`

    axios.delete(url, {
      headers: {
        'Authorization': `JWT ${localStorage.getItem('token')}`
      },
      data: {
        id: id
      }
    }).then(_ => {
      window.location.reload()
    }).catch(function(error) {
      toast.error('Opps! Something went wrong. Please try again.')
      if(error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
      } else if (error.request) {
          console.log(error.request)
      } else {
          console.log('Error', error.message)
      }
    })
  }, [id])

  return (
    <span>
      <Button
        style={{
          boxShadow: '1px 1px 3px 2px rgba(0,0,0,0.1)',
        }}
        color='danger'
        onClick={() => setShowDelete(true)}
      >
        <Trash2 size={12} style={{ marginRight: '10px' }} />
        Delete
      </Button>
      <Modal
        show={showDelete}
        onClose={() => setShowDelete(false)}
        closeOnBlur={true}
      >
        <Modal.Content>
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '5px',
              padding: '5%',
            }}
          >
            <Heading size={5}>Delete Custom Section</Heading>
            <p>
              Are you sure you want to delete this custom section? You can't
              undo this action.
            </p>
            <br />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button className='is-light' onClick={() => setShowDelete(false)}>
                Cancel
              </Button>
              <Button color='danger' onClick={() => deleteSection()}>
                <Trash2 size={12} style={{ marginRight: '10px' }} />
                Delete Section
              </Button>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </span>
  )
}

export default DeleteCustomSection
