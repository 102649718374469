import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import 'react-big-calendar/lib/sass/styles.scss'
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css'

import Container from 'react-bulma-components/lib/components/container'
import Box from 'react-bulma-components/lib/components/box'
import Columns from 'react-bulma-components/lib/components/columns'
import Heading from 'react-bulma-components/lib/components/heading'
import Button from 'react-bulma-components/lib/components/button'
import Image from 'react-bulma-components/lib/components/image'

import { API_URL } from '../config.json' // Import API Url


export default function CommunityHomeNoToken() {
    const [communityName, setCommunityName] = useState('')
    const [description, setDescription] = useState('')
    const [profilePhoto, setProfilePhoto] = useState('')
    const token = localStorage.getItem('token')

    var containerStyle = {
        margin: '3% 5%',
        maxWidth: '100%',
    }

    useEffect(() => {
        let currentCommunityURL = window.location.href
        let communityId = currentCommunityURL.substr(currentCommunityURL.lastIndexOf('/')+1)
        axios.get(`${API_URL}/one-community-no-token/`, {
            params: {
              pk: communityId,
            },
          })
          .then((response) => {
              setCommunityName(response.data[0].name)
              setDescription(response.data[0].description)
              setProfilePhoto(response.data[0].photo_file)
            }
          )
    }, [token])

    return (
        <div>
            <Container style={containerStyle}>
                <Heading style={{ textAlign: 'center' }}>
                    {communityName}
                </Heading>
                <Columns ismultiline={'true'}>
                    <Columns.Column size={3}>
                        <Box>
                            <Link to='/login' style={{ color: 'white' }}>
                                <Button color='primary' fullwidth={true}>
                                    Sign in
                                </Button>
                            </Link>
                            <br />
                            <p>
                                Already a Member? Click above to sign-in to this Community.
                            </p>
                        </Box>
                        <Box>
                            <Link to={{
                                pathname: '/register',
                                state: {
                                    community_name: communityName,
                                }
                            }}
                                style={{ color: 'white' }}
                            >
                                <Button color='primary' fullwidth={true}>
                                    Join this Community
                                </Button>
                            </Link>
                            <br />
                            <p>
                                Not a Member? Click above to request membership to this Community. 
                                In the <strong>Who would you like to help?</strong> field, type "{communityName}"".
                            </p>
                        </Box>
                    </Columns.Column>
                    <Columns.Column size={9}>
                        <Box>
                            <Columns>
                                <Columns.Column size={7}>
                                    <Heading size={4}>
                                        About {communityName}
                                    </Heading>
                                    <p>{description}</p>
                                </Columns.Column>
                                <Columns.Column size={5}>
                                    <Image src={profilePhoto} style={{ marginBottom: '7%' }} />
                                </Columns.Column>
                            </Columns>
                        </Box>
                    </Columns.Column>
                </Columns>
            </Container>
        </div>
    )
}
