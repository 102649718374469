import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import moment from 'moment-timezone'
import { extendMoment } from 'moment-range';

import { Link } from 'react-router-dom'
import Tag from 'react-bulma-components/lib/components/tag';

import { Input, Select, Field, Label, Control } from 'react-bulma-components/lib/components/form'
import Container from 'react-bulma-components/lib/components/container'
import Heading from 'react-bulma-components/lib/components/heading'
import Columns from 'react-bulma-components/lib/components/columns'
import Box from 'react-bulma-components/lib/components/box'

import CommunityNavbar from '../components/communityNavbar'
import SideBar from '../components/sidebar'

import { API_URL } from '../config.json' // Import API Url


export default function MyTasks() {
    // Create styles
    var containerStyle = {
        margin: '5% 5%',
        maxWidth: '100%',
    }

    var formContainerStyle = {
        padding: '5%',
        border: '1px solid hsl(0, 0%, 86%)',
        borderRadius: '10px',
    }

    var noteStyle = {
        color: '#E5E5E5',
        fontStyle: 'italic',
        margin: '15px',
    }

    const activityTypes = [
        'Filter by Activity Type',
        'Giving Rides',
        'Preparing Meals',
        'Shopping',
        'Childcare',
        'Pet Care',
        'House Cleaning',
        'Laundry',
        'Visits',
        'Miscellaneous',
        'Occasion'
    ]

    const getYears = () => {
        const yearArr = []
        const dateStart = moment().subtract(2, 'year')
        const dateEnd = moment().add(5, 'year')
        while (dateEnd.diff(dateStart, 'years') >= 0) {
            yearArr.push(dateStart.format('YYYY'))
            dateStart.add(1, 'year')
        }
        return yearArr
    }
    const years = getYears()
    const count = Array.from(Array(51).keys()).slice(1, 51)

    const monthMap = new Map()
    monthMap['January'] = 1
    monthMap['February'] = 2
    monthMap['March'] = 3
    monthMap['April'] = 4
    monthMap['May'] = 5
    monthMap['June'] = 6
    monthMap['July'] = 7
    monthMap['August'] = 8
    monthMap['September'] = 9
    monthMap['October'] = 10
    monthMap['November'] = 11
    monthMap['December'] = 12

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    // Date range
    const [startMonth, setStartMonth] = useState(moment().format('MMMM'))
    const [startDay, setStartDay] = useState(moment().date())
    const [startYear, setStartYear] = useState(moment().subtract(1, 'year').year())
    const [endMonth, setEndMonth] = useState(moment().format('MMMM'))
    const [endDay, setEndDay] = useState(moment().date())
    const [endYear, setEndYear] = useState(moment().add(1, 'year').year())
    const timezone = localStorage.getItem('timezone')

    const [tasks, setTasks] = useState([])
    const [search, setSearch] = useState('')
    const [selectedActivityType, setSelectedActivityType] = useState('Filter by Activity Type')


    useEffect(() => {
        axios
            .get(`${API_URL}/user-tasks/?user_email=${localStorage.getItem('email')}&community_id=${localStorage.getItem('community-id')}`, {
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(
                (response) => {
                    // Convert from UTC times to local time before displaying to users
                    const currentTime = moment.utc()
                    let tasksInFuture = []
                    response.data.forEach((task) => {
                        if(moment(task['start_time']).isAfter(currentTime)) {
                            task['start_time'] = moment.tz(task['start_time'], timezone)
                            task['end_time'] = moment.tz(task['end_time'], timezone)
                            tasksInFuture.push(task)
                        }
                    })
                    setTasks(tasksInFuture)
                },
                (error) => {
                    console.log(error)
                }
            )
    }, [timezone])

    const isDateWithinRange = useCallback((date) => {
        const fmt = 'YYYY-MM-DD'
        var startDate = moment(startYear + '-' + monthMap[startMonth] + '-' + startDay, fmt)
        var endDate = moment(endYear + '-' + monthMap[endMonth] + '-' + endDay, fmt)
        const range = extendMoment(moment).range(startDate, endDate)
        return range.contains(date)
    }, [startDay, startMonth, startYear, endDay, endMonth, endYear, monthMap])


    return (
        <div>
            <CommunityNavbar />
            <Container style={containerStyle}>
                <Columns ismultiline={'true'}>
                    <Columns.Column size={3}>
                        <SideBar />
                    </Columns.Column>
                    <Columns.Column size={9}>
                        <Heading size={4}>My Upcoming Tasks <Tag rounded={true} className='is-danger'>{tasks.length}</Tag></Heading>
                        <Container style={formContainerStyle}>
                            <Columns>
                                <Columns.Column size={8}>
                                    <Input
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        placeholder='Search activities by name'
                                        style={{ marginBottom: '3%' }}
                                    />
                                </Columns.Column>
                                <Columns.Column size={4}>
                                    <Field>
                                        <Control>
                                            <Select
                                                onChange={(e) => setSelectedActivityType(e.target.value)}
                                                name='selectedActivityType'
                                                value={selectedActivityType}
                                            >
                                                {activityTypes.map((type) => (<option key={type}>{type}</option>))}
                                            </Select>
                                        </Control>
                                    </Field>
                                </Columns.Column>
                            </Columns>
                            <Columns style={{ marginTop: '-3%', marginBottom: '3%' }}>
                                <Columns.Column size={5}>
                                    <Field>
                                        <Label>Start Date</Label>
                                        <Control>
                                            <Select
                                                onChange={(e) => setStartMonth(e.target.value)}
                                                name='startMonth'
                                                value={startMonth}
                                                style={{ marginRight: '10px' }}
                                            >
                                                {months.map((m) => (
                                                    <option key={m}>{m}</option>
                                                ))}
                                            </Select>
                                            <Select
                                                onChange={(e) => setStartDay(e.target.value)}
                                                name='startDay'
                                                value={startDay}
                                                style={{ marginRight: '10px' }}
                                            >
                                                {count.slice(0, 31).map((d) => (
                                                    <option key={d}>{d}</option>
                                                ))}
                                            </Select>
                                            <Select
                                                onChange={(e) => setStartYear(e.target.value)}
                                                name='startYear'
                                                value={startYear}
                                            >
                                                {years.map((y) => (
                                                    <option key={y}>{y}</option>
                                                ))}
                                            </Select>
                                        </Control>
                                    </Field>
                                </Columns.Column>
                                <Columns.Column size={5}>
                                    <Field>
                                        <Label>End Date</Label>
                                        <Control>
                                            <Select
                                                onChange={(e) => setEndMonth(e.target.value)}
                                                name='endMonth'
                                                value={endMonth}
                                                style={{ marginRight: '10px' }}
                                            >
                                                {months.map((m) => (
                                                    <option key={m}>{m}</option>
                                                ))}
                                            </Select>
                                            <Select
                                                onChange={(e) => setEndDay(e.target.value)}
                                                name='endDay'
                                                value={endDay}
                                                style={{ marginRight: '10px' }}
                                            >
                                                {count.slice(0, 31).map((d) => (
                                                    <option key={d}>{d}</option>
                                                ))}
                                            </Select>
                                            <Select
                                                onChange={(e) => setEndYear(e.target.value)}
                                                name='endYear'
                                                value={endYear}
                                            >
                                                {years.map((y) => (
                                                    <option key={y}>{y}</option>
                                                ))}
                                            </Select>
                                        </Control>
                                    </Field>
                                </Columns.Column>
                            </Columns>
                            <p style={{ marginBottom: '2%' }} className='has-text-grey'>
                                Below is a complete list of your tasks. Click on the task to view full details. You have {tasks.length} upcoming tasks.
                            </p>
                            {tasks.filter(
                                (a) =>
                                    (search === '' || (a.title).toLowerCase().includes(search.toLowerCase()))
                                    &&
                                    (selectedActivityType === 'Filter by Activity Type' || a.activity_type === selectedActivityType)
                                    &&
                                    isDateWithinRange(a.start_time)
                                    &&
                                    a.is_active
                            ).length > 0 ? (
                                tasks.filter(
                                    (a) =>
                                        (search === '' || (a.title).toLowerCase().includes(search.toLowerCase()))
                                        &&
                                        (selectedActivityType === 'Filter by Activity Type' || a.activity_type === selectedActivityType)
                                        &&
                                        (isDateWithinRange(a.start_time))
                                        &&
                                        a.is_active
                                )
                                    .map((a) => (
                                        <Link
                                            to={{
                                                pathname: '/view-one-activity/' + a.id,
                                                state: {
                                                    primary_key: a.id,
                                                },
                                            }}
                                        >
                                            <Box style={{background: '#2c859571', marginBottom: '1%'}}>
                                                <Columns>
                                                    <Columns.Column size={6}>
                                                        <strong>{a.activity_type}: </strong>{a.title}
                                                    </Columns.Column>
                                                    <Columns.Column size={6}>
                                                        {moment.tz(a.start_time, timezone).format('LL')}{' '}
                                                        Between {moment.tz(a.start_time, timezone).format('LT')}{' '}
                                                        and {moment.tz(a.end_time, timezone).format('LT')}
                                                    </Columns.Column>
                                                </Columns>
                                            </Box>
                                        </Link>
                                    )
                                    )
                            ) : (
                                <p className='has-text-grey-light' style={noteStyle}>
                                    No activities match this search.
                                </p>
                            )
                            }
                        </Container>
                    </Columns.Column>
                </Columns>
            </Container>

        </div>
    )
}
