import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Editor } from '@tinymce/tinymce-react'
import { toast } from "react-toastify"; // Notifications
import moment from 'moment-timezone'

import CommunityNavbar from '../components/communityNavbar'
import SideBar from '../components/sidebar'
import Container from 'react-bulma-components/lib/components/container'
import Columns from 'react-bulma-components/lib/components/columns'
import Heading from 'react-bulma-components/lib/components/heading'
import Button from 'react-bulma-components/lib/components/button'
import {
    Field,
    Control,
    Input,
    Select,
    Label
} from 'react-bulma-components/lib/components/form'


import { API_URL } from '../config.json'; // Import API Url


export default function AssignVolunteers(props) {
    var containerStyle = {
        margin: '5% 5%',
        maxWidth: '100%'
    }

    var formContainerStyle = {
        padding: '5%',
        border: '1px solid hsl(0, 0%, 86%)',
        borderRadius: '10px',
    }

    const [fromEmail, _] = useState(localStorage.getItem('email'))
    const [toEmail, setToEmail] = useState('')
    const [taskSubject, setTaskSubject] = useState('')
    const timezone = localStorage.getItem('timezone')

    const [message, setMessage] = useState('')
    const [communityMembers, setCommunityMembers] = useState([])


    useEffect(() => {
        axios
            .get(`${API_URL}/community-people/`, {
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                params: JSON.stringify({
                    user: localStorage.getItem('email'),
                    community: localStorage.getItem('community-name'),
                }),
            })
            .then(
                (response) => {
                    setCommunityMembers(Array.from(response.data.people))
                    let firstPerson = Array.from(response.data.people)[0]
                    setToEmail(`${firstPerson.first_name} ${firstPerson.last_name} - ${firstPerson.email}`)
                },
                (error) => {
                  toast.error(error);
                    console.log(error)
                }
            )
    }, [])

    useEffect(() => {
        const getData = async () => {
            try {
                const activity = props.location.state.activity

                const startTime = moment.tz(activity.actual_start_time, timezone).format('LT')
                const endTime = moment.tz(activity.actual_end_time, timezone).format('LT')
                const date = moment.tz(activity.actual_start_time, timezone).format('LL')

                // Custom location message depending on the type of activity
                let locationMessage = ''
                if (activity.activity_type === 'Giving Rides') {
                    locationMessage = `<b>Pickup location:</b>
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href='https://maps.google.com/?q=' + ${activity.pickup_location}

                        >
                            ${activity.pickup_location}
                        </a>
                        <br>
                        <b>Destination location:</b>
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href='https://maps.google.com/?q=' + ${activity.destination_location}

                        >
                            ${activity.destination_location}
                        </a>
                    `
                } else if (activity.activity_type === 'Preparing Meals') {
                    locationMessage = `<b>Delivery location:</b>
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href='https://maps.google.com/?q=' + ${activity.delivery_location}
                        >
                            ${activity.delivery_location}
                        </a>
                    `
                        } else {
                            locationMessage = `<b>Location:</b>
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href='https://maps.google.com/?q=' + ${activity.location}
                        >
                            ${activity.location}
                        </a>
                    `
                }
                let dietaryRestrictionMessage = ''
                if (activity.activity_type === 'Preparing Meals') {
                    let dietaryRestrictions = ''
                    activity.dietary_restrictions.map((r) => (
                        dietaryRestrictions += r + ', '
                    ))
                    dietaryRestrictionMessage = `<b>Dietary restrictions: </b>${dietaryRestrictions}<br>`
                }
                const communityName = localStorage.getItem('community-name')
                const emailMessage =
                `
                    A task on ${communityName}'s Care Community has been assigned to you.
                    If you cannot perform this task for any reason, please contact the Community Leader on ${communityName}’s Care Community home page.
                    <hr>
                    <b>Community</b>: ${communityName}<br >
                    <b>Web address:</b> <a href=${process.env.REACT_APP_URL}/community-home/${localStorage.getItem('community-id')}>${communityName}'s Care Community Page</a><br >
                    <b>Name:</b> ${activity.activity_type} - ${activity.title}<br >
                    <b>Date:</b> ${date}<br >
                    <b>Time:</b> Between ${startTime} and ${endTime}<br >
                    ${locationMessage}<br >
                    <b>Volunteers needed:</b> ${activity.num_volunteers_needed}<br >
                    ${dietaryRestrictionMessage}<br>
                    <b>Notes:</b> ${activity.description}
                    <hr>
                    Powered by Here to Serve<br>
                    <br>
                    </i>
                `

                setTaskSubject(`A task was assigned to you in ${localStorage.getItem('community-name')}'s Care Community`)
                setMessage(emailMessage)

            } catch (error) {
              toast.error(error);
                console.log(error)
            }
        }

        getData()

    }, [props.location.state.activity, timezone])

    const assignVolunteer = useCallback(() => {
        const param = {
            activity: props.location.state.activity.id,
            user: toEmail.substring(toEmail.lastIndexOf('-') + 1),
            is_email: true,
            message: message,
            subject: taskSubject,
            community_id: localStorage.getItem('community-id'),
            sender_email: fromEmail,
            is_signup: false
        }
        axios.post(`${API_URL}/add-volunteer-to-activity/`, param, {
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        })
            .then(
                (_) => {
                    toast.success(`An email has been sent to ${toEmail}!`);
                },
                (error) => {
                    toast.error(`Opps! Something went wrong. Please try again.`);
                    console.log(error)
                }
            )
    }, [toEmail, fromEmail, message, taskSubject, props.location.state.activity.id])

    return (
        <div>
            <CommunityNavbar />
            <Container style={containerStyle}>
                <Columns ismultiline={'true'}>
                    <Columns.Column size={3}>
                        <SideBar />
                    </Columns.Column>
                    <Columns.Column size={9}>
                        <Heading size={4}>Task Assignment</Heading>
                        <Container style={formContainerStyle}>
                            <Field>
                                <Control>
                                    <Label>
                                        From
                                </Label>
                                    <p>{fromEmail}</p>
                                </Control>
                            </Field>

                            <Field>
                                <Label>To</Label>
                                <Control>
                                    <Select name='to'
                                        value={toEmail}
                                        onChange={(e) => setToEmail(e.target.value)}
                                    >
                                        {communityMembers
                                            .map((person) => (
                                                <option style={{ position: 'static' }} value={person.email}>
                                                    {person.first_name} {person.last_name} - {person.email}
                                                </option>
                                            ))}
                                    </Select>
                                </Control>
                            </Field>

                            <Field>
                                <Control>
                                    <Label>
                                        Subject
                                    </Label>
                                    <Input name='Subject'
                                        value={taskSubject}
                                        onChange={
                                            (e) => setTaskSubject(e.target.value)
                                        }
                                        placeholder={taskSubject} />
                                </Control>
                            </Field>

                            <Field>
                                <Control>
                                    <Label>
                                        Message
                                    </Label>
                                    <input
                                        id='my-file'
                                        type='file'
                                        name='my-file'
                                        style={{ display: 'none' }}
                                    />
                                    <Editor
                                        initialValue={message}
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace wordcount visualblocks code fullscreen',
                                                'insertdatetime media table contextmenu paste code',
                                            ],
                                            toolbar:
                                                'insertfile undo redo | formatselect | bold italic underline backcolor | ' +
                                                'alignleft aligncenter alignright alignjustify | ' +
                                                'bullist numlist outdent indent | link image | help',
                                            file_browser_callback_types: 'image',
                                            file_picker_callback: function (
                                                callback,
                                                _,
                                                meta
                                            ) {
                                                if (meta.filetype === 'image') {
                                                    var input = document.getElementById('my-file')
                                                    input.click()
                                                    input.onchange = function () {
                                                        var file = input.files[0]
                                                        var reader = new FileReader()
                                                        reader.onload = function (e) {
                                                            callback(e.target.result, {
                                                                alt: file.name,
                                                            })
                                                        }
                                                        reader.readAsDataURL(file)
                                                    }
                                                }
                                            },
                                            paste_data_images: true,
                                        }}
                                        onEditorChange={(content, _) =>
                                            setMessage(content)
                                        }
                                    />
                                </Control>
                            </Field>
                            <p style={{ fontSize: '80%' }} className='has-text-grey'>
                                Clicking "Send email" will officially assign the person in the "To" section for this task, confirming a commitment to help.
                            </p>
                        </Container>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2%' }}>
                            <Button
                                color='primary'
                                onClick={() => assignVolunteer()}
                            >
                                Send email
                            </Button>
                        </div>
                    </Columns.Column>
                </Columns>
            </Container>
        </div>
    );

}

AssignVolunteers.propTypes = {
    activity: PropTypes.object.isRequired
}
