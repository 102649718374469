import React, { useState, useCallback, useEffect } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  useHistory,
} from "react-router-dom";
import axios from "axios";
import * as Sentry from "@sentry/react";
import zipcodeTimezone from "zipcode-to-timezone";
import captureError from "./utils/errors/captureError";

import Header from "./components/header";
import AppFooter from "./components/footer";
import PrivateRoute from "./components/privateroute";

import Register from "./pages/Register";
import MyCommunities from "./pages/MyCommunities";
import SelectCommunities from "./pages/JoinCommunities";
import CreateCommunity from "./pages/CreateCommunity";
import Login from "./pages/Login";
import CommunityAbout from "./pages/CommunityAbout";
import Welcome from "./pages/Welcome";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import AccountSettings from "./pages/AccountSettings";
import EmailSettings from "./pages/EmailSettings";
import CalendarPage from "./pages/CalendarPage";
import ForgotPasswordConfirm from "./pages/ForgotPasswordConfirm.js";
import ResetPasswordConfirm from "./pages/ResetPasswordConfirm.js";
import CreateNewActivity from "./pages/CreateNewActivity";
import FamilyUpdates from "./pages/FamilyUpdates";
import CreateFamilyUpdates from "./pages/CreateFamilyUpdates";
import OneCommunityMember from "./pages/CommunityOneMember";
import CommunityAddMembers from "./pages/CommunityAddMember";
import CommunityEdit from "./pages/CommunityEdit";
import CommunityPeople from "./pages/CommunityPeople";
import CommunityHome from "./pages/CommunityHome";
import CommunityHomeNoToken from "./pages/CommunityHomeNoToken";
import WaysToHelp from "./pages/WaysToHelp";
import WellWishes from "./pages/WellWishes";
import CreateCustomSection from "./pages/CreateCustomSection";
import ActivityReport from "./pages/ActivityReport";
import CustomGeneral from "./pages/CustomGeneral";
import PhotoGallery from "./pages/PhotoGallery";
import MessageBoard from "./pages/MessageBoard";
import PasswordSettings from "./pages/PasswordSettings";
import ActivityEdit from "./pages/ActivityEdit";
import CustomSections from "./pages/CustomSections";
import JoinRequests from "./pages/JoinRequests";
import EmailMembers from "./pages/EmailMembers";
import ManageActivities from "./pages/ManageActivities";
import AssignVolunteers from "pages/AssignVolunteers";
import Administration from "./pages/Administration";
import ViewOneActivity from "./pages/ViewOneActivity";
import EditTask from "./pages/EditTask";
import MyTasks from "./pages/MyTasks";
import PendingInvites from "./pages/PendingInvites";
import PageNotFound from "./pages/PageNotFound";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "./config.json"; // Import API Url

function App() {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") &&
      localStorage.getItem("token") !== "undefined" &&
      localStorage.getItem("token") !== undefined
      ? true
      : false,
  );

  async function validateToken() {
    const token = localStorage.getItem("token");
    var valid = null;
    if (token) {
      if (Date.now() >= token.exp * 1000) {
        valid = false;
        localStorage.clear();
        setLoggedIn(false);
      } else {
        valid = true;
        setLoggedIn(true);
      }
    } else {
      setLoggedIn(false);
    }
    return valid;
  }

  useEffect(() => {
    validateToken();
  }, []);

  const handleLogin = useCallback((email, password, rememberMe) => {
    const param = {
      email: email,
      password: password,
    };
    axios
      .post(`${API_URL}/token-auth/`, param, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        Sentry.configureScope(function (scope) {
          scope.setTag("is-staff", response.data.user.is_staff);
          scope.setUser(response.data.user);
        });
        localStorage.setItem("is-staff", response.data.user.is_staff);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("rememberMe", rememberMe);
        localStorage.setItem("email", email);
        // Set global timezone based on user's zipcode
        var tzn = zipcodeTimezone.lookup(response.data.user.zipcode);
        var tz = tzn != null ? tzn: "America/Los_Angeles"
        localStorage.setItem("timezone", tz);

        localStorage.getItem("token") &&
        localStorage.getItem("token") !== "undefined" &&
        localStorage.getItem("token") !== undefined
          ? setLoggedIn(true)
          : setLoggedIn(false);
      })
      .catch(function (error) {
        captureError(error, { user: { email: param.email } });
        if (error.response) {
          // Request made and server responded
          toast.error('Unable to log in with provided credentials.');
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          toast.error('Cannot loggin');
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error(error.message);
          console.log("Error", error.message);
        }
      });
  }, []);

  const handleSignup = useCallback(
    (
      email,
      password,
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      city,
      country,
      state,
      zipcode,
      phoneNumber1,
      phoneNumber1Type,
      phoneNumber2,
      phoneNumber2Type,
      howLearn,
      who,
      howHelp,
      howKnow,
      skillsToOffer,
    ) => {
      var FormData = require("form-data");
      var data = new FormData();
      data.append("email", email);
      data.append("password", password);
      data.append("first_name", firstName);
      data.append("last_name", lastName);
      data.append("address_line_1", addressLine1);
      data.append("address_line_2", addressLine2);
      data.append("city", city);
      data.append("country", country);
      data.append("state", state);
      data.append("zipcode", zipcode);
      data.append("phone_number_1", phoneNumber1);
      data.append("phone_number_1_type", phoneNumber1Type);
      data.append("phone_number_2", phoneNumber2);
      data.append("phone_number_2_type", phoneNumber2Type);
      data.append("how_learn", howLearn);
      data.append("how_help", howHelp);
      data.append("how_know", howKnow);
      data.append("skills_to_offer", skillsToOffer);
      data.append("who", who);

      var config = {
        method: "post",
        url: `${API_URL}/users/`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          Sentry.configureScope(function (scope) {
            scope.setTag("is-staff", response.data.is_staff);
            scope.setUser(response.data.email);
          });
          localStorage.setItem("is-staff", response.data.is_staff);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("email", response.data.email);
          // Set global timezone based on user's zipcode
          var tzn = zipcodeTimezone.lookup(response.data.zipcode);
          var tz = tzn != null ? tzn: "America/Los_Angeles"
          localStorage.setItem("timezone", tz);

          localStorage.getItem("token") &&
          localStorage.getItem("token") !== "undefined" &&
          localStorage.getItem("token") !== undefined
            ? setLoggedIn(true)
            : setLoggedIn(false);
        })
        .catch(function (error) {
          console.log(error);
          toast.error('Cannot register, please try again later');
          captureError(error, {
            extra: {
              argsProvided: {
                email,
                password: "[REDACTED]",
                firstName,
                lastName,
                addressLine1,
                addressLine2,
                city,
                country,
                state,
                zipcode,
                phoneNumber1,
                phoneNumber1Type,
                phoneNumber2,
                phoneNumber2Type,
                howLearn,
                who,
                howHelp,
                howKnow,
                skillsToOffer,
              },
            },
          });
        });
    },
    [],
  );

  const handleLogout = useCallback(() => {
    try {
      localStorage.clear();
      setLoggedIn(false);
      Sentry.configureScope((scope) => scope.setUser(null));
    } catch (e) {
      captureError(e);
    }
  }, []);

  const handleForgotPassword = useCallback((email) => {
    const param = {
      email: email,
    };
    axios
      .post(`${API_URL}/reset-password/`, param, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch(function (error) {
        toast.error("Opps! Something went wrong. Please try again.");
        captureError(error, { user: { email: param.email } });
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, []);

  return (
    <div>
      <Router basename="/">
        <Header logged_in={loggedIn} handle_logout={handleLogout} />
        <Switch>
          {/* Routes that are available without authentication */}
          <Route
            exact
            path="/register"
            render={() => (
              <Register handle_signup={handleSignup} logged_in={loggedIn} />
            )}
          />
          <Route
            exact
            path="/login"
            render={() => (
              <Login handle_login={handleLogin} logged_in={loggedIn} />
            )}
          />
          <Route path="/home" exact component={Welcome} />
          <Route path="/" exact component={Welcome} />
          <Route
            path="/forgot-password"
            render={() => (
              <ForgotPassword handle_forgot_password={handleForgotPassword} />
            )}
          />
          <Route path="/reset-password" exact component={ResetPassword} />
          <Route
            path="/forgot-password-confirmation"
            exact
            component={ForgotPasswordConfirm}
          />
          <Route
            path="/reset-password-confirmation"
            exact
            component={ResetPasswordConfirm}
          />
          <Route
            path="/community-welcome/:id"
            exact
            component={CommunityHomeNoToken}
          />
          {/* <Route component={PageNotFound} /> */}

          {/* Routes that are available only if user logs in */}
          <PrivateRoute
            path="/my-communities"
            exact
            component={MyCommunities}
          />
          <PrivateRoute
            path="/select-communities"
            exact
            component={SelectCommunities}
          />
          <PrivateRoute
            path="/create-community"
            exact
            component={CreateCommunity}
          />
          <PrivateRoute
            path="/account-settings"
            exact
            component={AccountSettings}
          />
          <PrivateRoute
            path="/email-settings"
            exact
            component={EmailSettings}
          />
          <PrivateRoute
            path="/community-home/:id"
            exact
            component={CommunityHome}
          />
          <PrivateRoute path={"/calendar"} exact component={CalendarPage} />
          <PrivateRoute
            path="/create-new-activity"
            exact
            component={CreateNewActivity}
          />
          <PrivateRoute path="/announcements" exact component={FamilyUpdates} />
          <PrivateRoute
            path="/create-announcement"
            exact
            component={CreateFamilyUpdates}
          />
          <PrivateRoute
            path="/community-people"
            exact
            component={CommunityPeople}
          />
          <PrivateRoute
            path="/community/:member"
            exact
            component={OneCommunityMember}
          />
          <PrivateRoute
            path="/add-people"
            exact
            component={CommunityAddMembers}
          />
          <PrivateRoute path="/ways-to-help" exact component={WaysToHelp} />
          <PrivateRoute path="/well-wishes" exact component={WellWishes} />
          <PrivateRoute
            path="/create-custom-section"
            exact
            component={CreateCustomSection}
          />
          <PrivateRoute path="/about" exact component={CommunityAbout} />
          <PrivateRoute
            path="/activity-report"
            exact
            component={ActivityReport}
          />
          <PrivateRoute
            path="/custom/:section"
            exact
            component={CustomGeneral}
          />
          <PrivateRoute path="/photo-gallery" exact component={PhotoGallery} />
          <PrivateRoute
            path="/edit-community"
            exact
            component={CommunityEdit}
          />
          <PrivateRoute path="/message-board" exact component={MessageBoard} />
          <PrivateRoute
            path="/password-settings"
            exact
            component={PasswordSettings}
          />
          <PrivateRoute
            path="/edit-activity/:activity"
            exact
            component={ActivityEdit}
          />
          <PrivateRoute
            path="/view-one-activity/:activity"
            exact
            component={ViewOneActivity}
          />
          <PrivateRoute path="/edit-task/:task" exact component={EditTask} />
          <PrivateRoute
            path="/custom-sections"
            exact
            component={CustomSections}
          />
          <PrivateRoute path="/join-requests" exact component={JoinRequests} />
          <PrivateRoute path="/email-members" exact component={EmailMembers} />
          <PrivateRoute
            path="/assign-volunteers"
            exact
            component={AssignVolunteers}
          />
          <PrivateRoute
            path="/manage-activities"
            exact
            component={ManageActivities}
          />
          <PrivateRoute
            path="/administration"
            exact
            component={Administration}
          />
          <PrivateRoute path="/my-activities" exact component={MyTasks} />
          <PrivateRoute
            path="/pending-invites"
            exact
            component={PendingInvites}
          />
        </Switch>
        <AppFooter />
      </Router>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </div>
  );
}

export default Sentry.withProfiler(App);
