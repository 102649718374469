import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { Editor } from '@tinymce/tinymce-react'
import moment from 'moment-timezone'
import Container from 'react-bulma-components/lib/components/container'
import Columns from 'react-bulma-components/lib/components/columns'
import Heading from 'react-bulma-components/lib/components/heading'
import CommunityNavbar from '../components/communityNavbar'
import Button from 'react-bulma-components/lib/components/button'
import SideBar from '../components/sidebar'
import PostCard from '../components/postCard'
import Permissions from '../components/permissions'
import {
  Textarea,
  Input,
  Label,
  Field,
  Control,
} from 'react-bulma-components/lib/components/form'
import { Plus, Edit } from 'react-feather'

import { API_URL } from '../config.json' // Import API Url
import { toast } from "react-toastify"; // Notifications


export default function CustomGeneral(props) {
  const token = localStorage.getItem('token')
  const [isEditing, setIsEditing] = useState(false)
  const [showDPForm, setShowDPForm] = useState(false)

  const [title, setTitle] = useState('')
  const [newTitle, setNewTitle] = useState('')
  const [name, setName] = useState('')
  const [link, setLink] = useState('')
  const [type, setType] = useState('')
  const [description, setDescription] = useState('')
  const [newDescription, setNewDescription] = useState('')
  const [content, setContent] = useState('')
  const [newContent, setNewContent] = useState('')
  const [discussionPosts, setDiscussionPosts] = useState([])

  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [validForm, setValidForm] = useState(false)
  const userRole = localStorage.getItem('user-role')

  var containerStyle = {
    margin: '5% 5%',
    maxWidth: '100%',
  }

  var noteStyle = {
    fontSize: '0.75rem',
    fontStyle: 'italic',
    textAlign: 'center',
    padding: '20px',
    backgroundColor: 'hsl(0, 0%, 96%)',
    borderRadius: '10px',
  }

  var formContainerStyle = {
    padding: '5%',
    border: '1px solid hsl(0, 0%, 86%)',
    borderRadius: '10px',
    marginTop: '20px',
  }

  useEffect(() => {
    const formValues = [subject, message]
    const notValidForm = formValues.some((formVal) => {
      return formVal === ''
    })
    setValidForm(notValidForm)
  }, [subject, message])

  useEffect(() => {
    axios.get(`${API_URL}/one-custom-section/`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
        params: {
          section_id: props.location.state.section,
        },
      })
      .then(
        (response) => {
          setTitle(response.data[0].title)
          setNewTitle(response.data[0].title)
          setName(response.data[0].name)
          setType(response.data[0].type)
          setDescription(response.data[0].description)
          setNewDescription(response.data[0].description)
          setContent(response.data[0].general_content)
          setNewContent(response.data[0].general_content)
          setLink(response.data[0].link)
        },
        (error) => {
          console.log(error)
        }
      )
  }, [token, props.location.state.section])

  const editCustomSection = useCallback(() => {
    var url = `${API_URL}/edit-custom-section/`

    var formdata = new FormData()
    formdata.append('section_id', props.location.state.section)
    formdata.append('title', newTitle)
    formdata.append('description', newDescription)
    formdata.append('general_content', newContent)
    formdata.append('name', name)
    formdata.append('link', link)

    axios.post(url, formdata, {
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'multipart/form-data',
      }
    }).then(_ => {
      window.location.reload()
    }).catch(function(error) {
        toast.error("Your information failed to update. Please try again.")
        if(error.response) {
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
        } else if (error.request) {
            console.log(error.request)
        } else {
            console.log('Error', error.message)
        }
    })
  },[link, name, newContent, newDescription, newTitle, props.location.state.section, token])

  const handleSubmit = useCallback(() => {
    // Create date as UTC instead of local timezone
    let dateTime =  moment.utc().format('MM/DD/YYYY [at] h:mm a');

    const param = {
      section: props.location.state.section,
      subject: subject,
      message: message,
      date_time: dateTime,
      user: localStorage.getItem('email'),
      community: localStorage.getItem('community-name'),
      author_name: '',
    }

    axios.post(`${API_URL}/add-discussion-post/`, param, {
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(
        (_) => {
          window.location.reload()
        },
        (error) => {
          console.log(error)
        }
      )
  }, [subject, message, props.location.state.section, token])

  useEffect(() => {
    axios.get(`${API_URL}/discussion-posts`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
        params: {
          section_id: props.location.state.section,
        },
      })
      .then(
        (response) => {
          console.log(response.data)
          setDiscussionPosts(response.data)
        },
        (error) => {
          console.log(error)
        }
      )
  }, [token, props.location.state.section])

  useEffect(() => {
    setIsEditing(false)
  }, [props.location.state.section])

  return (
    <div>
      <CommunityNavbar />
      <Container style={containerStyle}>
        <Columns ismultiline={'true'}>
          <Columns.Column size={3}>
            <SideBar />
          </Columns.Column>
          <Columns.Column size={9}>
            {isEditing ? (
              <Columns>
                <Columns.Column size={8}>
                  <Input
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                  />
                </Columns.Column>
                <Columns.Column size={2}>
                  <Button
                    className='is-fullwidth'
                    onClick={() => setIsEditing(false)}
                  >
                    Cancel
                  </Button>
                </Columns.Column>
                <Columns.Column size={2}>
                  <Button
                    color='primary'
                    className='is-fullwidth'
                    onClick={() => editCustomSection()}
                  >
                    Save
                  </Button>
                </Columns.Column>
              </Columns>
            ) : (
              <Columns>
                <Columns.Column size={10}>
                  <Heading size={4}>{title}</Heading>
                </Columns.Column>
                <Columns.Column size={2}>
                  {Permissions.is_community_leader &&
                    <Button
                      color='primary'
                      className='is-fullwidth'
                      onClick={() => setIsEditing(true)}
                    >
                      <Edit size={12} style={{ marginRight: '5px' }} />
                      Edit
                    </Button>
                }
                </Columns.Column>
              </Columns>
            )}
            {isEditing ? (
              <div>
                <Label>Description</Label>
                <Textarea
                  value={newDescription}
                  onChange={(e) => setNewDescription(e.target.value)}
                />

                <br />
                {type === 'GENERAL' && (
                  <div>
                    <Label>Content</Label>
                    <Editor
                      initialValue={newContent}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount',
                        ],
                        toolbar:
                          'undo redo | fontsizeselect | link image | bold italic underline backcolor | ' +
                          'alignleft aligncenter alignright alignjustify | ' +
                          'bullist numlist outdent indent | removeformat',
                      }}
                      onEditorChange={(content, _) =>
                        setNewContent(content)
                      }
                    />
                  </div>
                )}
              </div>
            ) : (
              <div>
                {type === 'DP' ? (
                  <Columns>
                    <Columns.Column size={10}>
                      <p>{description}</p>
                      {description !== '' && <br />}
                    </Columns.Column>
                    <Columns.Column size={2}>
                      <Button
                        onClick={() => setShowDPForm(!showDPForm)}
                        color='primary'
                        className='is-fullwidth'
                      >
                        <Plus size={12} style={{ marginRight: '5px' }} />
                        {showDPForm ? 'Hide Form' : 'New Post'}
                      </Button>
                    </Columns.Column>
                  </Columns>
                ) : (
                  <div>
                    <p>{description}</p>
                    {description !== '' && <br />}
                  </div>
                )}
                {type === 'GENERAL' && (
                  <div>
                    {content === '' ? (
                      <p style={noteStyle}>
                        No content has been posted for this page.
                      </p>
                    ) : (
                      <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    )}
                  </div>
                )}
                {type === 'DP' && (
                  <div>
                    {showDPForm && (
                      <div>
                        <div style={formContainerStyle}>
                          <Field>
                            <Label>
                              Subject<span style={{ color: '#F83D34' }}>*</span>
                            </Label>
                            <Control>
                              <Input
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                              />
                            </Control>
                          </Field>
                          <Field>
                            <Label>
                              Message<span style={{ color: '#F83D34' }}>*</span>
                            </Label>
                            <Control>
                              <Editor
                                initialValue={message}
                                init={{
                                  height: 300,
                                  menubar: false,
                                  plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount',
                                  ],
                                  toolbar:
                                    'undo redo | fontsizeselect | link image | bold italic underline backcolor | ' +
                                    'alignleft aligncenter alignright alignjustify | ' +
                                    'bullist numlist outdent indent | removeformat',
                                }}
                                onEditorChange={(content, editor) =>
                                  setMessage(content)
                                }
                              />
                            </Control>
                          </Field>
                        </div>
                        <br />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Button
                            color='primary'
                            disabled={validForm}
                            onClick={() => handleSubmit()}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    )}
                    {discussionPosts.length === 0 ? (
                      <p style={noteStyle}>
                        No posts have been made for this page.
                      </p>
                    ) : (
                      <div>
                        {discussionPosts
                          .slice()
                          .reverse()
                          .map((a, index) => {
                            return (
                              <PostCard
                                key={index}
                                subject={a.subject}
                                message={a.message}
                                dateTime={a.date_time}
                                user={a.author_name}
                                id={a.id}
                                type='discussion-post'
                              />
                            )
                          })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </Columns.Column>
        </Columns>
      </Container>
    </div>
  )
}
