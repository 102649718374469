import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'


import Container from 'react-bulma-components/lib/components/container'
import Columns from 'react-bulma-components/lib/components/columns'
import Heading from 'react-bulma-components/lib/components/heading'
import CommunityNavbar from '../components/communityNavbar'
import Button from 'react-bulma-components/lib/components/button'
import FamilyUpdatePostCard from '../components/familyUpdatePostCard'
import SideBar from '../components/sidebar'
import { Coffee, Radio } from 'react-feather'

import { API_URL } from '../config.json' // Import API Url
import Permissions from '../components/permissions'
import Spinner from 'components/spinner'


export default function FamilyUpdates() {
  const token = localStorage.getItem('token')
  const [announcements, setAnnouncements] = useState([])
  const [ loading, setLoading ] = useState(true);

  var containerStyle = {
    margin: '5% 5%',
    maxWidth: '100%',
  }

  var noteStyle = {
    fontSize: '0.75rem',
    fontStyle: 'italic',
    textAlign: 'center',
    padding: '20px',
    backgroundColor: 'hsl(0, 0%, 96%)',
    borderRadius: '10px',
  }

  useEffect(() => {
    axios
      .get(`${API_URL}/announcement/`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
        params: {
          community_id: localStorage.getItem('community-id'),
          is_admin: Permissions.is_community_leader
        },
      })
      .then(
        (response) => {
          setAnnouncements(response.data)
          setLoading(false);
        },
        (error) => {
          console.log(error)
        }
      )
  }, [token])

  return (
    <div>
      <CommunityNavbar />
      <Container style={containerStyle}>
        <Columns ismultiline={'true'}>
          <Columns.Column size={3}>
            <SideBar />
          </Columns.Column>

          <Columns.Column size={9}>
            <Columns>
              <Columns.Column size={9}>
                <Heading size={4}>Family Updates</Heading>
              </Columns.Column>
              <Columns.Column size={3}>
                {Permissions.is_community_leader ? (
                  <Link
                    to='/create-announcement'
                    style={{ marginRight: '10px' }}
                  >
                    <Button color='primary' className='is-fullwidth'>
                      <div>
                        <Radio size={12} style={{ marginRight: '5px' }} />
                        Create Update
                      </div>
                    </Button>
                  </Link>
                ) : (
                  <></>
                )}
              </Columns.Column>
            </Columns>
            <p style={{marginBottom: '3%'}}>
              This page will allow updates on how the family is coping and what challenges they are facing.
              The Here to Serve Care Coordinator will keep members of {localStorage.getItem('community-name')}'s Care Community up to date on
              the overall wellbeing of the family as a whole.
            </p>
            <div>
              {!loading && announcements.length > 0 ? (
                announcements
                  .slice()
                  .reverse()
                  .map((a, index) => {
                    return (
                      <FamilyUpdatePostCard
                        key={index}
                        subject={a.subject}
                        message={a.message}
                        dateTime={a.date_time}
                        user={a.author_name}
                        id={a.id}
                        type='announcement'
                      />
                    )
                  })
              ) : (
                loading ? <Spinner /> :
                <p style={noteStyle}>
                <Coffee size={100} color='#E5E5E5' />
                <br />
                <br />
                Sit tight! Nothing has been posted yet.
              </p>
              )}
            </div>
          </Columns.Column>
        </Columns>
      </Container>
    </div>
  )
}
