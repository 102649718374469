
import { USER_ROLE_TYPES } from '../utils/roleTypes.json'

class Permissions {
  static is_superadmin()  {return (localStorage.getItem('user-role') === (localStorage.getItem('is-staff') === 'true'));}
  static is_community_admin() {return ((localStorage.getItem('user-role') === USER_ROLE_TYPES.ADMIN) || (localStorage.getItem('user-role') === USER_ROLE_TYPES.COMM_LEADER) || (localStorage.getItem('is-staff') === 'true'));}
  static is_community_leader()  {return ((localStorage.getItem('user-role') === USER_ROLE_TYPES.ADMIN) || (localStorage.getItem('user-role') === USER_ROLE_TYPES.COMM_LEADER) || (localStorage.getItem('is-staff') === 'true'));}
  static is_community_coordinator() { return (localStorage.getItem('user-role') === USER_ROLE_TYPES.ADMIN) ||  (localStorage.getItem('user-role') === USER_ROLE_TYPES.COMM_LEADER) || (localStorage.getItem('user-role') === USER_ROLE_TYPES.COMM_COORDINATOR)  || (localStorage.getItem('is-staff') === 'true');}
  static is_community_family() { return (localStorage.getItem('user-role') === USER_ROLE_TYPES.ADMIN) || (localStorage.getItem('user-role') === USER_ROLE_TYPES.COMM_LEADER) ||(localStorage.getItem('user-role') === USER_ROLE_TYPES.FAMILY) || (localStorage.getItem('is-staff') === 'true')};
}

export default Permissions
