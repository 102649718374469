import React, { useState, useCallback, useEffect } from 'react'
import axios from 'axios'

import Container from 'react-bulma-components/lib/components/container'
import Heading from 'react-bulma-components/lib/components/heading'
import { Link } from 'react-router-dom'
import { Editor } from '@tinymce/tinymce-react'

import {
    Control,
    Label,
    Field,
    Input,
} from 'react-bulma-components/lib/components/form'
import Columns from 'react-bulma-components/lib/components/columns'
import Button from 'react-bulma-components/lib/components/button'

import CommunityNavbar from '../components/communityNavbar'

import { API_URL } from '../config.json' // Import API Url
import { toast } from 'react-toastify'


export default function CommunityAddMembers() {
    var containerStyle = {
        margin: '5% auto',
        maxWidth: '70%',
        maxHeight: '1000px',
        padding: '4rem',
        border: '0.1rem solid #E5E5E5',
        borderRadius: '1rem'
    }

    const [inputEmail, setInputEmail] = useState('')
    const [allEmails, setAllEmails] = useState([])
    const [firstName, setFirstName] = useState('') // User sending the email
    const [lastName, setLastName] = useState('') // User sending the email
    const [communityExists, setCommunityExists] = useState(false)
    const [message, setMessage] = useState(`</strong> You have been invited to join ${localStorage.getItem('community-name')}'s online care community. Please click
                                            <a href="${process.env.REACT_APP_URL}/register"> here</a> to join, receive updates, as well as support the family.`)

    const addEmail = useCallback((email) => {
        setAllEmails(allEmails.concat(email))
        setInputEmail('')
    },[allEmails])

    useEffect(() => {
        if (localStorage.getItem('token')) {
            axios.get(`${API_URL}/current_user/`, {
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`
                }
            })
            .then(response => {
                setFirstName(response.data.first_name)
                setLastName(response.data.last_name)
            })
        }
        if (localStorage.getItem('community-name') !== null) {
            setCommunityExists(true)
        }
    }, [])

    const sendEmail = useCallback((fromEmail, toEmails, community, sender) => {
        if(toEmails.length > 0) {
            const param = {
                from_email: fromEmail,
                to_emails: toEmails,
                community: community,
                sender: sender,
                message: message,
                community_id: localStorage.getItem('community-id')
            }
            axios.post(`${API_URL}/invite-members/`, param, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${localStorage.getItem('token')}`
                }
            }).then((_) => {
                toast.success('An email has been sent to the addresses you provided!')
            }).catch(function(error) {
                toast.error('Opps, something went wrong!')
                if(error.response) {
                    console.log(error.response.data)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                } else if (error.request) {
                    console.log(error.request)
                } else {
                    console.log('Error', error.message)
                }
            })
        } else {
            toast.error('Your email recipient list is empty. Perhaps you haven\'t clicked "Add"?')
        }
    }, [message])

    const sendEmailWOCommunity = useCallback((fromEmail, toEmails, sender) => {
        if(toEmails.length > 0) {
            const param = {
                from_email: fromEmail,
                to_emails: toEmails,
                sender: sender,
                community_id: localStorage.getItem('community-id')
            }
            axios.post(`${API_URL}/invite-members-no-community/`, param, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${localStorage.getItem('token')}`
                }
            }).then((_) => {
                toast.success('An email has been sent to the addresses you provided!')
            }).catch(function(error) {
                toast.error('Opps, something went wrong!')
                if(error.response) {
                    console.log(error.response.data)
                    console.log(error.response.status)
                    console.log(error.response.headers)
                } else if (error.request) {
                    console.log(error.request)
                } else {
                    console.log('Error', error.message)
                }
            })
        } else {
            toast.error('Your email recipient list is empty. Perhaps you haven\'t clicked "Add"?')
        }
    }, [])

    return (
        <div>
            {communityExists && <CommunityNavbar />}
            <Container style={containerStyle}>
                <Heading size={4}>Invite Members</Heading>
                <Field>
                    <Label>Send to:</Label>
                    <Control>
                        <Columns>
                            <Columns.Column size={10}>
                                <Input
                                    value={inputEmail}
                                    onChange={e => setInputEmail(e.target.value)}
                                    placeholder='Enter email address(es) to send invites to your community.'
                                />
                            </Columns.Column>
                            <Columns.Column>
                                <Button
                                    style={{ marginBottom: '1rem' }}
                                    color='primary'
                                    onClick={() => addEmail(inputEmail)}
                                >
                                    Add
                        </Button>
                            </Columns.Column>
                        </Columns>
                    </Control>
                </Field>

                <ul>
                    {allEmails.map(e => (
                        <li key={e}>{e}</li>
                    ))}
                </ul>
                <br />
                {communityExists ?
                    <div>
                        <strong>Subject:</strong> Join {localStorage.getItem('community-name')}'s Care Community
                        <br />
                        <br />
                        <strong>Message:</strong>
                        <Control>
                            <Editor
                                initialValue={message}
                                init={{
                                    height: 500,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace wordcount visualblocks code fullscreen',
                                        'insertdatetime media table contextmenu paste code'
                                    ],
                                    toolbar: 'undo redo | fontsizeselect | link image | bold italic underline backcolor | ' +
                                                'alignleft aligncenter alignright alignjustify | ' +
                                                'bullist numlist outdent indent | removeformat',
                                }}
                                onEditorChange={(content, _) => setMessage(content)}
                            />
                        </Control>
                    </div>
                    :
                    <div>
                        <strong>Subject:</strong> Join Here to Serve
                        <br />
                        <br />
                        <strong>Message:</strong> You have been invited to join Here to Serve Care Community Platform.
                        Please click <a href={`${process.env.REACT_APP_URL}/register`}>here</a> to sign up for the volunteer platform.
                    </div>
                }
                <Columns style={{ marginTop: '5%' }}>
                    <Columns.Column>
                        <Link to='/community-people'>
                            <Button
                                className='is-primary is-inverted'
                                style={{
                                    marginBottom: '1rem',
                                    boxShadow: '1px 1px 3px 2px rgba(0,0,0,0.1)',
                                }}
                                fullwidth={true}
                            >
                                Cancel
                        </Button>
                        </Link>
                    </Columns.Column>
                    <Columns.Column>
                        {communityExists ?
                            <Button
                                color='primary'
                                style={{
                                    marginBottom: '1rem',
                                    boxShadow: '1px 1px 3px 2px rgba(0,0,0,0.1)',
                                }}
                                fullwidth={true}
                                onClick={() =>
                                    sendEmail(
                                        localStorage.getItem('email'),
                                        allEmails,
                                        localStorage.getItem('community-name'),
                                        firstName + ' ' + lastName,
                                    )
                                }
                            >
                                Send Invite
                            </Button>
                            :
                            <Button
                                color='primary'
                                style={{
                                    marginBottom: '1rem',
                                    boxShadow: '1px 1px 3px 2px rgba(0,0,0,0.1)',
                                }}
                                fullwidth={true}
                                onClick={() =>
                                    sendEmailWOCommunity(
                                        localStorage.getItem('email'),
                                        allEmails,
                                        firstName + ' ' + lastName,
                                    )
                                }
                            >
                                Send Invite
                            </Button>
                        }
                    </Columns.Column>
                </Columns>
            </Container>
        </div >
    )
}
