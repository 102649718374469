import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'

import Container from 'react-bulma-components/lib/components/container'
import Heading from 'react-bulma-components/lib/components/heading'
import Table from 'react-bulma-components/lib/components/table'
import Columns from 'react-bulma-components/lib/components/columns'
import Button from 'react-bulma-components/lib/components/button'
import { Mail } from 'react-feather'

import CommunityNavbar from '../components/communityNavbar'
import SideBar from '../components/sidebar'

import { API_URL } from '../config.json' // Import API Url
import { toast } from 'react-toastify'


export default function PendingInvites() {
  var containerStyle = {
    margin: '5% 5%',
    maxWidth: '100%',
  }

  var innerContainerStyle = {
    padding: '5%',
    border: '1px solid hsl(0, 0%, 86%)',
    borderRadius: '10px',
  }

  var noteStyle = {
    color: '#E5E5E5',
    fontStyle: 'italic',
    margin: '15px',
  }

  const [people, setPeople] = useState([])


  useEffect(() => {
    axios
      .get(`${API_URL}/pending-invites/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        params: {
          community_id: localStorage.getItem('community-id'),
        },
      })
      .then(
        (response) => {
           setPeople(response.data)
        },
        (error) => {
          console.log(error)
        }
      )
  }, [])

  const sendEmailReminder = useCallback((toEmail) => {
    const communityName = localStorage.getItem('community-name')
    const subject = `Invitation to join ${communityName}'s Here to Serve Care community`
    const content = `You have been invited to join ${communityName}'s Here to Serve Care community.
                    I see that you have yet to join. You can do so by using the link below to become
                    a member and stay informed on family needs,, receive updates and be involved in supporting ${communityName}'s journey through cancer.`
    const param = {
        to_emails: [toEmail],
        subject: subject,
        content: content,
      }
      axios.post(`${API_URL}/send-email/`, param, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${localStorage.getItem('token')}`,
        },
      }).then(_ => {
        toast.success('Your email has been sent successfully!')
      }).catch(function(error) {
        toast.error('Opps, something went wrong! Please try again.')
        if(error.response) {
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
        } else if (error.request) {
            console.log(error.request)
        } else {
            console.log('Error', error.message)
        }
    })
  },[])

  return (
    <div>
      <CommunityNavbar />
      <Container style={containerStyle}>
        <Columns ismultiline={'true'}>
          <Columns.Column size={3}>
            <SideBar />
          </Columns.Column>
          <Columns.Column size={9}>
            <Heading size={4}>Pending Invites</Heading>
            <Container style={innerContainerStyle}>
                <p style={{ marginBottom: '2%' }} className='has-text-grey'>
                    Below is a list of email addresses that were sent an invitation to join but never did.
                </p>

                <Table>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Date Sent</th>
                            <th>Resend Email</th>
                        </tr>
                   </thead>
                   <tbody>

                        {people.length > 0 ? people.map((invite, index) => (
                            <tr key={index}>
                                <td>{invite.user_email}</td>
                                <td>{invite.email_sent_date}</td>
                                <td>
                                    <Button
                                        style={{
                                        boxShadow: '1px 1px 3px 2px rgba(0,0,0,0.1)',
                                        }}
                                        color='primary'
                                        onClick={() => sendEmailReminder(invite.user_email)}
                                    >
                                        <Mail size={12} style={{ marginRight: '5px' }} />
                                        Send Reminder
                                    </Button>
                                </td>
                            </tr>
                            ))
                        :
                            <tr className='has-text-grey-light' style={noteStyle}>
                                <td>No pending invites to review.</td>
                            </tr>
                        }
                   </tbody>
                </Table>

            </Container>
          </Columns.Column>
        </Columns>
      </Container>
    </div>
  )
}
