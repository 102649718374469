import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import "../index.css";
import "react-big-calendar/lib/sass/styles.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import Permissions from '../components/permissions'
import Button from "react-bulma-components/lib/components/button";
import Container from "react-bulma-components/lib/components/container";
import Columns from "react-bulma-components/lib/components/columns";
import Heading from "react-bulma-components/lib/components/heading";

import CommunityNavbar from "../components/communityNavbar";
import { UserPlus, Edit } from "react-feather";

import { API_URL } from "../config.json"; // Import API Url
import { toast } from "react-toastify";
import { formatPhoneNumber } from "react-phone-number-input";
import Spinner from "components/spinner";

export default function ViewOneActivity(props) {
  var eventContainerStyle = {
    margin: "5% auto",
    maxWidth: "870px",
    maxHeight: "1000px",
    padding: "4rem",
    border: "0.1rem solid #E5E5E5",
    borderRadius: "1rem",
  };

  const [activity, setActivity] = useState({});
  const [activityVolunteers, setActivityVolunteers] = useState([]);
  const [activityCoordinators, setActivityCoordinators] = useState([]);
  const [signedUp, setSignedUp] = useState(false);
  const timezone = localStorage.getItem("timezone");
  const userRole = localStorage.getItem("user-role");
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    axios
      .get(`${API_URL}/edit-activity/${props.location.state.primary_key}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then(
        (response) => {
          setActivity(response.data);
          setActivityVolunteers(response.data.volunteers);
          setActivityCoordinators(response.data.coordinators);
          // If a volunteer signed up/assigned the task already, they can't sign up again
          setSignedUp(response.data.volunteers.some(volunteer => volunteer.email === localStorage.getItem("email")));
          setLoading(false);
        },
        (error) => {
          console.log(error);
        }
      );
  }, [props.location.state.primary_key,signedUp]);

  const refreshVolunteerList = useCallback(() => {
    axios
      .get(`${API_URL}/edit-activity/${props.location.state.primary_key}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then(
        (response) => {
          setActivityVolunteers(response.data.volunteers);
          // If a volunteer signed up/assigned the task already, they can't sign up again
          for (let i = 0; i < response.data.volunteers; i++) {
            let volunteer = response.data.volunteers[i];
            if (volunteer.email === localStorage.getItem("email"))
              setSignedUp(true);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }, [props.location.state.primary_key]);


  const stopVolunteering = useCallback(() => {
    const param = {
      activity: activity.id,
      user: localStorage.getItem("email"),
      is_email: false
    }
    axios
      .post(`${API_URL}/remove-volunteer-from-activity/`, param, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((_) => {
        setSignedUp(false);
        refreshVolunteerList();
        toast.success(
          `You've successfully stopped folunteering for ${activity.title}!`
        );
      })
      .catch(function (error) {
        toast.error("Opps, something went wrong! Please try again.");
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  },[refreshVolunteerList, activity.id, activity.title])

  const signUpToVolunteer = useCallback(() => {
    const startTime = moment
      .tz(activity.actual_start_time, timezone)
      .format("LT");
    const endTime = moment.tz(activity.actual_end_time, timezone).format("LT");
    const date = moment.tz(activity.actual_start_time, timezone).format("LL");

    // Custom location message depending on the type of activity
    let locationMessage = "";
    if (activity.activity_type === "Giving Rides") {
      locationMessage = `<b>Pickup location:</b>
                <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://maps.google.com/?q=' + ${activity.pickup_location}

                >
                    ${activity.pickup_location}
                </a>
                <br>
                <b>Destination location:</b>
                <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://maps.google.com/?q=' + ${activity.destination_location}

                >
                    ${activity.destination_location}
                </a>
            `;
    } else if (activity.activity_type === "Preparing Meals") {
      locationMessage = `<b>Delivery location:</b>
                <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://maps.google.com/?q=' + ${activity.delivery_location}
                >
                    ${activity.delivery_location}
                </a>
            `;
    } else {
      locationMessage = `<b>Location:</b>
                <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://maps.google.com/?q=' + ${activity.location}
                >
                    ${activity.location}
                </a>
            `;
    }
    let dietaryRestrictionMessage = "";
    if (activity.activity_type === "Preparing Meals") {
      let dietaryRestrictions = "";
      activity.dietary_restrictions.map(
        (r) => (dietaryRestrictions += r + ", ")
      );
      dietaryRestrictionMessage = `<b>Dietary restrictions: </b>${dietaryRestrictions}<br>`;
    }
    const communityName = localStorage.getItem("community-name");
    const emailMessage = `
            I'm signing up to help with ${activity.activity_type}: ${
      activity.title
    } - ${date}.
            <hr>
            <b>Community</b>: ${communityName}<br >
            <b>Web address:</b> <a href=${
              process.env.REACT_APP_URL
            }/community-home/${localStorage.getItem(
      "community-id"
    )}>${communityName}'s Care Community Page</a><br >
            <b>Name:</b> ${activity.activity_type} - ${activity.title}<br >
            <b>Date:</b> ${date}<br >
            <b>Time:</b> Between ${startTime} and ${endTime}<br >
            ${locationMessage}<br >
            <b>Volunteers needed:</b> ${activity.num_volunteers_needed}<br >
            ${dietaryRestrictionMessage}<br>
            <b>Notes:</b> ${activity.description}
            <hr>
            Powered by Here to Serve<br>
            <br>
        `;

    const emailSubject = `I signed up to help with ${activity.activity_type}: ${
      activity.title
    } - ${date} for ${localStorage.getItem("community-name")}`;

    const param = {
      activity: activity.id,
      user: localStorage.getItem("email"),
      is_email: true,
      is_signup: true,
      message: emailMessage,
      subject: emailSubject,
      community_id: localStorage.getItem("community-id"),
      sender_email: localStorage.getItem("email"),
      start_time: startTime,
      end_time: endTime,
      date: date,
    };

    axios
      .post(`${API_URL}/add-volunteer-to-activity/`, param, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((_) => {
        setSignedUp(true);
        refreshVolunteerList();
        toast.success(
          `You've successfully signed up to volunteer for ${activity.title}!`
        );
      })
      .catch(function (error) {
        toast.error("Opps, something went wrong! Please try again.");
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, [activity, refreshVolunteerList, timezone]);

  if (loading) {
      return (
        <>
        <CommunityNavbar />
        <Spinner message='Getting the event info' />
        </>
      )
  }
  else {
    return (
        <div>
          <CommunityNavbar />
          <Container style={eventContainerStyle}>
            <Columns>
              <Columns.Column size={9}>
                <Heading size={4}>
                  {activity.activity_type}: {activity.title}
                </Heading>
                {Permissions.is_community_coordinator ? null : (
                  <Link
                    to={{
                      pathname:
                        "/edit-activity/" + props.location.state.primary_key,
                      state: {
                        primary_key: props.location.state.primary_key,
                      },
                    }}
                  >
                    {activity.activity_type === "Occasion" ? (
                      <div>
                        <Edit size={12} style={{ marginRight: "10px" }} />
                        Edit this event
                      </div>
                    ) : (
                      <div>
                        <Edit size={12} style={{ marginRight: "10px" }} />
                        Edit this activity
                      </div>
                    )}
                  </Link>
                )}
              </Columns.Column>
              <Columns.Column size={3}>
                {!Permissions.is_community_coordinator ? (
                  <>
                    <p>
                      {(activityVolunteers.length >=
                      activity.num_volunteers_needed) || signedUp ? null : (

                        <Button
                          style={{
                            boxShadow: "1px 1px 3px 2px rgba(0,0,0,0.1)",
                          }}
                          fullwidth={true}
                          color="primary"
                          onClick={() => signUpToVolunteer()}
                        >
                          <UserPlus size={12} style={{ marginRight: "10px" }} />
                          Sign up
                        </Button>

                      )}
                      {console.log(signedUp)}
                      {!signedUp ? null : (

                        <Button
                          style={{
                            boxShadow: "1px 1px 3px 2px rgba(0,0,0,0.1)",
                          }}
                          fullwidth={true}
                          color="primary"
                          onClick={() => stopVolunteering()}
                        >
                          <UserPlus size={12} style={{ marginRight: "10px" }} />
                          Stop volunteering
                        </Button>

                      )}
                    </p>
                  </>
                ) : (
                    activityVolunteers.length >=
                        activity.num_volunteers_needed ? null :
                  <Link
                    to={{
                      pathname: "/assign-volunteers/",
                      state: {
                        activity: activity,
                      },
                    }}
                    style={{ color: "white" }}
                  >
                    <Button
                      style={{
                        boxShadow: "1px 1px 3px 2px rgba(0,0,0,0.1)",
                      }}
                      fullwidth={true}
                      color="primary"
                    >
                      <UserPlus size={12} style={{ marginRight: "10px" }} />
                      Assign
                    </Button>
                  </Link>
                )}
              </Columns.Column>
            </Columns>
            <b>Volunteers:</b>{" "}
            {activityVolunteers.length === 0 ? (
              "No volunteers have signed up."
            ) : (
              <ul>
                {activityVolunteers.map((person) => (
                  <li key={person.email}>
                    {person.first_name} {person.last_name}: {person.email}
                  </li>
                ))}
              </ul>
            )}
            <br />

              <b>Date</b>:{" "}
              {moment.tz(activity.actual_start_time, timezone).format("LL")}
              <br />
              <b>Time</b>: Between{" "}
              {moment.tz(activity.actual_start_time, timezone).format("LT")} and{" "}
              {moment.tz(activity.actual_end_time, timezone).format("LT")}
              {/* Show location */}
              {activity.activity_type === "Giving Rides" ? (
                <div>
                  <b>Pickup Location: </b>{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={"https://maps.google.com/?q=" + activity.pickup_location}
                  >
                    {activity.pickup_location}
                  </a>
                  <br />
                  <b>Destination: </b>{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      "https://maps.google.com/?q=" + activity.destination_location
                    }
                  >
                    {activity.destination_location}
                  </a>
                </div>
              ) : activity.activity_type === "Preparing Meals" ? (
                <div>
                  <b>Delivery Location: </b>{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      "https://maps.google.com/?q=" + activity.delivery_location
                    }
                  >
                    {activity.delivery_location}
                  </a>
                </div>
              ) : (
                <div>
                  <b>Location: </b>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={"https://maps.google.com/?q=" + activity.location}
                  >
                    {activity.location}
                  </a>{" "}
                </div>
              )}
              {/* Show dietary restrictions for meal activities */}
              {activity.activity_type === "Preparing Meals" && (
                <div>
                  <b>Dietary Restrictions: </b>{" "}
                  <ul>
                    {activity.dietary_restrictions.map((r) => (
                      <li key={r}>- {r}</li>
                    ))}
                  </ul>
                </div>
              )}
              {/* Volunteer information */}
              {activity.activity_type !== "Occasion" && (
                <div>
                  <b>Volunteers Needed:</b>{" "}
                  {activity.num_volunteers_needed - activityVolunteers.length} out
                  of {activity.num_volunteers_needed}
                  <br />
                  <br />
                </div>
              )}
              <b>Notes:</b>
              <br />
              {activity.description}
              <br />
              <br />
              {activity.activity_type !== "Occasion" && (
                <div>

                  <b>Coordinators:</b>
                  <ul>
                    {activityCoordinators.map((person) => (
                      <li key={person.email}>
                        {person.first_name} {person.last_name}: {" "}
{" "}
                        {formatPhoneNumber( person.phone_number_1)}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

            <br />

            <Link
              to={{
                pathname: "/edit-task/" + activity.id,
                state: {
                  primary_key: activity.id,
                },
              }}
            >
              <Edit size={12} style={{ marginRight: "10px" }} />
              Edit this task
            </Link>
          </Container>
        </div>
      );
  }
}

ViewOneActivity.propTypes = {
  primary_key: PropTypes.number.isRequired,
};
