import React from 'react'
import { Link } from 'react-router-dom'

import Menu from 'react-bulma-components/lib/components/menu';

const SideNavAccount = (props) => {
  return (
    <div>
        <Menu color='white'>
            <Menu.List>
                <Link to='/account-settings'>
                    General Account Settings
                </Link>
                <Link to='/password-settings'>Password Settings</Link>
                <Link to='/email-settings'>Email Settings</Link>
            </Menu.List>
        </Menu>
    </div>
  )
}

export default SideNavAccount
