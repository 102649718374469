import React, { useState, useCallback, useEffect } from 'react'
import moment from 'moment-timezone'
import Permissions from '../components/permissions'
import Heading from 'react-bulma-components/lib/components/heading'
import Button from 'react-bulma-components/lib/components/button'
import Dropdown from 'react-bulma-components/lib/components/dropdown'
import Modal from 'react-bulma-components/lib/components/modal'
import Section from 'react-bulma-components/lib/components/section'
import {
    Field,
    Control,
    Input,
    Label,
    Select
} from 'react-bulma-components/lib/components/form'
import Media from 'react-bulma-components/lib/components/media'
import Content from 'react-bulma-components/lib/components/content'
import Box from 'react-bulma-components/lib/components/box'
import { Editor } from '@tinymce/tinymce-react'
import { MoreVertical, Edit, XCircle } from 'react-feather'

import { API_URL } from '../config.json' // Import API Url
import axios from 'axios'
import { formatPhoneNumber } from 'react-phone-number-input'
import { toast } from 'react-toastify'

export default function FamilyUpdatePostCard({
    id,
    subject,
    message,
    dateTime,
    user,
    type,
}) {
    const [showMenu, setShowMenu] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [newSubject, setNewSubject] = useState(subject ? subject : '')
    const [newMessage, setNewMessage] = useState(message ? message : '')
    const [author, setAuthor] = useState(user)
    const [communityLeaders, setCommunityLeaders] = useState([])

    // Since date was saved in UTC, convert it to local timezone
    const localDateTime = moment.utc(dateTime, 'MM/DD/YYYY h:mm a').tz(localStorage.getItem('timezone')).format('MM/DD/YYYY [at] h:mm a')

    const deletePost = useCallback(() => {
        var url = `${API_URL}/delete-${type}/`
        axios.delete(url, {
            headers: {
                'Authorization': `JWT ${localStorage.getItem('token')}`
            },
            data: {
                id: id
            }
        }).then(_ => {
            window.location.reload()
        }).catch((error) => {
            console.log(error)
        })
    },[id, type])

    const editPost = useCallback(() => {
        var url = `${API_URL}/edit-${type}/`
        var formdata = new FormData()
        formdata.append('id', id)
        formdata.append('author_name', author)
        formdata.append('subject', newSubject)
        formdata.append('message', newMessage)

        axios.post(url, formdata, {
            headers: {
                'Authorization': `JWT ${localStorage.getItem('token')}`,
                'Content-Type': 'multipart/form-data',
            }
        }).then(_ => {
            window.location.reload()
        }).catch((error) => {
            toast.error('Unable to edit post');
            console.log(error)
        })
    },[author, id, newMessage, newSubject, type])

    useEffect(() => {
        axios
            .get(`${API_URL}/community-coordinators/${localStorage.getItem('community-id')}`, {
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                },
            })
            .then(
                (response) => {
                    let options = []
                    for (let i = 0; i < response.data.members.length; i++) {
                        let user = response.data.members[i]
                        let formattedPhoneNum = formatPhoneNumber(user.phone_number_1)
                        options.push({
                            label: `${user.first_name} ${user.last_name}`,
                            value: user.id,
                            email: user.email,
                            phone: formattedPhoneNum,
                        })
                    }
                    setCommunityLeaders(options)
                },
                (error) => {
                  toast.error(error);
                    console.log(error)
                }
            )
    }, [])

    var cardStyle = {
        border: '1px solid hsl(0, 0%, 86%)',
        borderRadius: '5px',
        padding: '2.5%',
        marginBottom: '20px',
        width: '100%',
    }

    var userStyle = {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        marginBottom: '0',
    }

    var dateStyle = {
        fontSize: '0.75rem',
        fontStyle: 'italic',
    }

    var contentStyle = {
        padding: '10px',
    }

    if (isEditing) {
        return (
            <div style={cardStyle}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <p style={userStyle}>{user}</p>
                        <p style={dateStyle}>{localDateTime}</p>
                    </div>
                </div>
                <div style={contentStyle}>
                    <Field>
                        <Label>
                            Subject<span style={{ color: '#F83D34' }}>*</span>
                        </Label>
                        <Control>
                            <Input
                                value={newSubject}
                                onChange={(e) => setNewSubject(e.target.value)}
                            ></Input>
                        </Control>
                    </Field>
                    <Field>
                        <Label>
                            Author<span style={{ color: '#F83D34' }}>*</span>
                        </Label>
                        <Control>
                            <Select
                                onChange={(e) => setAuthor(e.target.value)}
                                name='author'
                                value={author}
                                style={{ marginRight: '10px' }}
                            >
                                {communityLeaders.map((m, index) => (
                                    <option key={index}>{m.label}</option>
                                ))}
                            </Select>
                        </Control>
                    </Field>
                    <Field>
                        <Label>
                            Message<span style={{ color: '#F83D34' }}>*</span>
                        </Label>
                        <Control>
                            <input
                                id='my-file'
                                type='file'
                                name='my-file'
                                style={{ display: 'none' }}
                            />
                            <Editor
                                initialValue={newMessage}
                                init={{
                                    height: 500,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace wordcount visualblocks code fullscreen',
                                        'insertdatetime media table contextmenu paste code',
                                    ],
                                    toolbar:
                                        'insertfile undo redo | formatselect | bold italic backcolor | ' +
                                        'alignleft aligncenter alignright alignjustify | ' +
                                        'bullist numlist outdent indent | link image media | help',
                                    file_browser_callback_types: 'image',
                                    file_picker_callback: function (callback, value, meta) {
                                        if (meta.filetype === 'image') {
                                            var input = document.getElementById('my-file')
                                            input.click()
                                            input.onchange = function () {
                                                var file = input.files[0]
                                                var reader = new FileReader()
                                                reader.onload = function (e) {
                                                    callback(e.target.result, {
                                                        alt: file.name,
                                                    })
                                                }
                                                reader.readAsDataURL(file)
                                            }
                                        }
                                    },
                                    paste_data_images: true,
                                }}
                                onEditorChange={(content, _) => setNewMessage(content)}
                            />
                        </Control>
                    </Field>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => setIsEditing(false)}>Cancel</Button>
                        <Button color='primary' onClick={() => editPost()}>
                            Finish
            </Button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Box>
            <Media renderAs='article'>
                <Media.Item position='center'>
                    <Content>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <p style={userStyle}>{user}</p>
                                <p style={dateStyle}>{localDateTime}</p>
                            </div>
                            {Permissions.is_community_family && (
                                <div>
                                    <MoreVertical
                                        size={20}
                                        onClick={() => setShowMenu(!showMenu)}
                                    />

                                    {showMenu && (
                                        <div
                                            style={{
                                                zIndex: 1,
                                                position: 'absolute',
                                                backgroundColor: 'white',
                                                border: '1px solid hsl(0, 0%, 86%)',
                                                borderRadius: '5px',
                                                right: '20px',
                                            }}
                                        >
                                            <Dropdown.Item
                                                value='edit'
                                                onClick={() => setIsEditing(true)}
                                            >
                                                <Edit size={10} /> <strong>Edit</strong>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                value='delete'
                                                onClick={() => setShowModal(true)}
                                            >
                                                <XCircle size={10} color='#F83D34' />{' '}
                                                <strong style={{ color: '#F83D34' }}>Delete</strong>
                                            </Dropdown.Item>
                                            <Modal
                                                show={showModal}
                                                onClose={() => setShowModal(false)}
                                                closeOnBlur={true}
                                            >
                                                <Modal.Card>
                                                    <Modal.Card.Head onClose={() => setShowModal(false)}>
                                                        <Modal.Card.Title>
                                                            Delete Announcement
                            </Modal.Card.Title>
                                                    </Modal.Card.Head>
                                                    <Section style={{ backgroundColor: 'white' }}>
                                                        Are you sure you want to delete this post? You can't
                                                        undo this action.
                          </Section>
                                                    <Modal.Card.Foot
                                                        style={{
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <Button onClick={() => setShowModal(false)}>
                                                            Cancel
                            </Button>
                                                        <Button
                                                            color='primary'
                                                            onClick={() => deletePost()}
                                                        >
                                                            Delete Post
                            </Button>
                                                    </Modal.Card.Foot>
                                                </Modal.Card>
                                            </Modal>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <Section>
                            <Heading size={4}>{subject}</Heading>
                            <div dangerouslySetInnerHTML={{ __html: message }}></div>
                        </Section>
                    </Content>
                </Media.Item>
            </Media>
        </Box>
    )
}
