import * as Sentry from "@sentry/react";

/**
 * Takes an error or message and sends it to Sentry.
 * @param {Error|message} error - a programatic error or developer message
 * @param {Object} context - an object with any keys of [ tags, extra, contexts, user, level, fingerprint ].
 * @returns void
 */
const captureError = (error, context) => {
  error instanceof Error
    ? Sentry.captureException(error, context)
    : Sentry.captureMessage(error, context);
};

export default captureError;
