import React, { useState } from 'react'
import Navbar from 'react-bulma-components/lib/components/navbar'

import Permissions from '../components/permissions'

const adminLinks = [
  ['About', '/about'],
  ['Administration', '/administration'],
  ['Calendar', '/calendar'],
  ['Family Updates', '/announcements'],
  ['Ways to Help', '/ways-to-help'],
  ['Message Board', '/message-board'],
  ['Photo Gallery', '/photo-gallery'],
  ['Well Wishes', '/well-wishes'],
  ['People', '/community-people'],
  ['Custom Sections', '/custom-sections']
]

const nonAdminLinks = [
  ['About', '/about'],
  ['Calendar', '/calendar'],
  ['Family Updates', '/announcements'],
  ['Ways to Help', '/ways-to-help'],
  ['Message Board', '/message-board'],
  ['Photo Gallery', '/photo-gallery'],
  ['Well Wishes', '/well-wishes'],
  ['Custom Sections', '/custom-sections']
]


const CommunityNavbar = () => {
  const [active, setActive] = useState(false)

  return (
    <Navbar color='primary' style={{ overflow: 'auto', whiteSpace: 'nowrap'}}>
      <Navbar.Brand>
        <Navbar.Item renderAs="a" href={`/community-home/${localStorage.getItem('community-id')}`} style={{ fontSize: '30px' }}>
          {localStorage.getItem('community-name')}
        </Navbar.Item>
        <Navbar.Burger
          role='button'
          aria-label='menu'
          aria-expanded='false'
          datatarget='navItems'
          onClick={(_) => setActive(!active)}
          className={active?'is-active':''}
        />
      </Navbar.Brand>
      <Navbar.Menu id='navItems' className={active?'is-active':''}>
        <Navbar.Container>
          {Permissions.is_community_admin ?
            adminLinks.map((item) => (
              <Navbar.Item key={item[1]} href={item[1]}>
                <strong style={{ fontSize: 'large' }}>{item[0]}</strong>
              </Navbar.Item>
            ))
            :
            nonAdminLinks.map((item) => (
              <Navbar.Item key={item[1]} href={item[1]}>
                <strong style={{ fontSize: 'large' }}>{item[0]}</strong>
              </Navbar.Item>
            ))
          }
        </Navbar.Container>
      </Navbar.Menu>
    </Navbar>
  )
}

export default CommunityNavbar
