import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import {
  Dedupe as DedupeIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
  ReportingObserver as ReportingObserverIntegration,
} from "@sentry/integrations";

import "./index.css";
import App from "./App";

Sentry.init({
  dsn:
    process.env.REACT_APP_SENTRY_DSN ||
    "https://9f6baa8bcf8d4001a284e81b7c8578c4@o344777.ingest.sentry.io/5733697",
  environment: process.env.REACT_APP_ENV,
  release: "community-platform-front-end@0.1.0",
  integrations: [
    new DedupeIntegration(),
    new ExtraErrorDataIntegration(),
    new Integrations.BrowserTracing(),
    new ReportingObserverIntegration(),
  ],
  /** CORS issues :( */
  //   tracesSampleRate: 1.0,
  //   tracingOrigins: ["localhost", "carecommunity.app", /^\//],
  attachStacktrace: true,
  sendDefaultPii: true,
  autoSessionTracking: true,
});

ReactDOM.render(<App />, document.getElementById("root"));
