import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { Editor } from '@tinymce/tinymce-react'

import Container from 'react-bulma-components/lib/components/container'
import Columns from 'react-bulma-components/lib/components/columns'
import Heading from 'react-bulma-components/lib/components/heading'
import CommunityNavbar from '../components/communityNavbar'
import Button from 'react-bulma-components/lib/components/button'
import SideBar from '../components/sidebar'
import { Edit, XCircle, Coffee } from 'react-feather'

import { API_URL } from '../config.json' // Import API Url
import Permissions from '../components/permissions'
import { toast } from 'react-toastify'
import Spinner from 'components/spinner'

export default function WaysToHelp() {
  const token = localStorage.getItem('token')
  const [isEditing, setIsEditing] = useState(false)
  const [content, setContent] = useState('')
  const [newContent, setNewContent] = useState('')
  const [userRole] = useState(localStorage.getItem('user-role'))
  const [ loading, setLoading ] = useState(true)

  var containerStyle = {
    margin: '5% 5%',
    maxWidth: '100%',
  }

  var noteStyle = {
    fontSize: '0.75rem',
    fontStyle: 'italic',
    textAlign: 'center',
    padding: '20px',
    backgroundColor: 'hsl(0, 0%, 96%)',
    borderRadius: '10px',
  }

  var formContainerStyle = {
    padding: '5%',
    border: '1px solid hsl(0, 0%, 86%)',
    borderRadius: '10px',
  }

  useEffect(() => {
    axios
      .get(`${API_URL}/one-community/`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
        params: {
          pk: localStorage.getItem('community-id'),
        },
      })
      .then(
        (response) => {
          setContent(response.data[0].ways_to_help)
          setNewContent(response.data[0].ways_to_help)
          setLoading(false);
        },
        (error) => {
          toast.error('Opps, something went wrong!')
          console.log(error)
        }
      )
  }, [token])

  const editWaysToHelp = useCallback(() => {
    const param = {
      ways_to_help: newContent,
      community_id: localStorage.getItem('community-id')
    }
    axios
      .post(`${API_URL}/edit-ways-to-help/`, param, {
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(
        (_) => {
          window.location.reload()
        },
        (error) => {
          toast.error('Opps, something went wrong!')
          console.log(error)
        }
      )
  },[newContent, token])

  if (isEditing) {
    return (
      <div>
        <CommunityNavbar />
        <Container style={containerStyle}>
          <Columns ismultiline={'true'}>
            <Columns.Column size={3}>
              <SideBar />
            </Columns.Column>
            <Columns.Column size={9}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Heading size={4}>Ways to Help</Heading>
                <XCircle onClick={() => setIsEditing(false)} color='#F83D34' />
              </div>
              <div>
                <input
                  id='my-file'
                  type='file'
                  name='my-file'
                  style={{ display: 'none' }}
                />
                <Editor
                  initialValue={newContent}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace wordcount visualblocks code fullscreen',
                      'insertdatetime media table contextmenu paste code',
                    ],
                    toolbar:
                        'insertfile undo redo | formatselect | bold italic underline backcolor | ' +
                        'alignleft aligncenter alignright alignjustify | ' +
                        'bullist numlist outdent indent | link image media | help',
                    file_browser_callback_types: 'image',
                    file_picker_callback: function (callback, value, meta) {
                      if (meta.filetype === 'image') {
                        var input = document.getElementById('my-file')
                        input.click()
                        input.onchange = function () {
                          var file = input.files[0]
                          var reader = new FileReader()
                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            })
                          }
                          reader.readAsDataURL(file)
                        }
                      }
                    },
                    paste_data_images: true,
                  }}
                  onEditorChange={(content, editor) => setNewContent(content)}
                />
                <br />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button color='primary' onClick={() => editWaysToHelp()}>
                    Save
                  </Button>
                </div>
              </div>
            </Columns.Column>
          </Columns>
        </Container>
      </div>
    )
  }

  return (
    <div>
      <CommunityNavbar />
      <Container style={containerStyle}>
        <Columns ismultiline={'true'}>
          <Columns.Column size={3}>
            <SideBar />
          </Columns.Column>
          <Columns.Column size={9}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2%' }}>
                <Heading size={4}>Ways to Help</Heading>
                {Permissions.is_community_leader &&
                  <Edit onClick={() => setIsEditing(true)} />
                }
              </div>
              {loading ? <Spinner /> :
                            <Container style={formContainerStyle}>
                            {content === '' ? (
                              <p style={noteStyle}>
                                <Coffee size={100} color='#E5E5E5' />
                                <br />
                                <br />
                                Sit tight! Nothing has been posted yet.
                              </p>
                            ) : (
                              <div dangerouslySetInnerHTML={{ __html: content }}></div>
                            )}
                        </Container>
                        }

          </Columns.Column>
        </Columns>
      </Container>
    </div>
  )
}
