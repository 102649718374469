import React, { useEffect, useCallback, useState } from "react"

import Container from 'react-bulma-components/lib/components/container';
import Columns from 'react-bulma-components/lib/components/columns'
import Heading from 'react-bulma-components/lib/components/heading'
import Button from 'react-bulma-components/lib/components/button';
import CheckboxField from '../components/checkboxfield'
import SideNavAccount from '../components/sideNavAccount'

import { API_URL } from '../config.json' // Import API Url
import axios from "axios";
import { toast } from "react-toastify";


export default function EmailSettings() {
  var containerStyle = {
    margin: '3% auto',
    maxWidth: '80%',
    padding: '4rem',
  }

  const pk = localStorage.getItem('user-id')
  const [infoAboutH2S, setInfoAboutH2S] = useState(true)
  const [newsletter, setNewsLetter]     = useState(false)
  const [emailTaskReminders, setEmailTaskReminders] = useState(false)

  // Functions
  const handleOnCheckInformationAboutH2S = useCallback(() => {
    setInfoAboutH2S(!infoAboutH2S)
  },[infoAboutH2S])

  const handleOnCheckNewsLetter = useCallback(() => {
    setNewsLetter(!newsletter)
  },[newsletter])

  const handleOnCheckEmailTaskReminders = useCallback(() => {
    setEmailTaskReminders(!emailTaskReminders)
  },[emailTaskReminders])

  // API calls
  const editEmailSettings = useCallback(() => {
    var url = `${API_URL}/edit-user/${pk}/`

    var formdata = new FormData();
    formdata.append('email_task_reminders', emailTaskReminders)

    axios.patch(url, formdata, {
      headers: {
        'Authorization': `JWT ${localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data'
      }
    }).then(_ => {
      toast.success('Your email settings have been successfully updated!')
    }).catch(function(error) {
      toast.error("Opps! Something went wrong. Please try again.")
      if(error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
      } else if (error.request) {
          console.log(error.request)
      } else {
          console.log('Error', error.message)
      }
  })

  },
  [pk, emailTaskReminders])

  useEffect(() => {
    axios.get(`${API_URL}/current_user/`, {
        headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`
        }
    }).then(response => {
        setEmailTaskReminders(response.data.email_task_reminders)
    })
  }, [])


  return (
    <Container style={containerStyle}>
      <Columns>
        <Columns.Column style={{ maxWidth: '30%' }}>
          <SideNavAccount />
        </Columns.Column>
        <Columns.Column>
        <Heading size={4} style={{ marginBottom: '3%', }}>Email Settings</Heading>
        {/* <CheckboxField text={'Information about family and Here to Serve'} checked={infoAboutH2S} onChange={handleOnCheckInformationAboutH2S} /> */}
        {/* <CheckboxField text={'Receive Here to Serve newsletter'} onChange={handleOnCheckNewsLetter} /> */}
        <CheckboxField text={'Reminders for tasks you signed up for'} checked={emailTaskReminders} onChange={handleOnCheckEmailTaskReminders} />
        <Button
          color="primary"
          fullwidth={true}
          style={{maxWidth: '40%', marginTop: '8%'}}
          onClick={() => editEmailSettings()}
        >
          SAVE CHANGES
        </Button>
        </Columns.Column>
      </Columns>
    </Container>
  );
}
