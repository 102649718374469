import React, { useState, useEffect, useCallback } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment-timezone'
import '../index.css'
import 'react-big-calendar/lib/sass/styles.scss'
import { Link, useHistory } from 'react-router-dom'
import {formatPhoneNumber} from 'react-phone-number-input'
import axios from 'axios'

import { toast } from 'react-toastify'
import Button from 'react-bulma-components/lib/components/button'
import Box from 'react-bulma-components/lib/components/box'
import Container from 'react-bulma-components/lib/components/container'
import Columns from 'react-bulma-components/lib/components/columns'
import Heading from 'react-bulma-components/lib/components/heading'
import {
  Select,
  Control,
  Field,
  Checkbox,
} from 'react-bulma-components/lib/components/form'

import CheckboxField from '../components/checkboxfield'
import CommunityNavbar from '../components/communityNavbar'
import CustomSections from '../components/customSections'
import { RefreshCw, Clipboard, Layers, Calendar as CalendarIcon } from 'react-feather'
import { USER_ROLE_TYPES } from '../utils/roleTypes.json'
import { API_URL } from '../config.json'; // Import API Url
import Permissions from '../components/permissions'
import Spinner from 'components/spinner'


export default function CalendarPage() {
  let history = useHistory()

  var containerStyle = {
    margin: '5% 5%',
    maxWidth: '100%',
  }

  const [selectedMonth, setSelectedMonth] = useState(moment().format('MMMM'))
  const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'))
  const [date, setDate] = useState()
  const [timezone] = useState(localStorage.getItem('timezone'))
  const [userRole] = useState(localStorage.getItem('user-role'))
  const [ loading, setLoading ] = useState(true);

  const getYears = () => {
    const yearArr = []
    const dateStart = moment().subtract(2, 'year')
    const dateEnd = moment().add(5, 'year')
    while (dateEnd.diff(dateStart, 'years') >= 0) {
      yearArr.push(dateStart.format('YYYY'))
      dateStart.add(1, 'year')
    }
    return yearArr
  }
  const years = getYears()

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const categories = [
    'Giving Rides',
    'Preparing Meals',
    'Shopping',
    'Childcare',
    'Pet Care',
    'House Cleaning',
    'Laundry',
    'Visits',
    'Miscellaneous',
    'Occasion',
  ]

  // Events and event selection
  const [events, setEvents] = useState([])
  const token = localStorage.getItem('token')

  // Setup the localizer by providing the moment (or globalize) Object
  // to the correct localizer.
  const localizer = momentLocalizer(moment)

  // filter parameters
  const [selectedMember, setSelectedMember] = useState('All')
  const [members, setMembers] = useState([{ first_name: 'All', last_name: '' }])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [originalEvents, setOriginalEvents] = useState([]) // Used for filtering
  const [selectedStatuses, setSelectedStatuses] = useState([])
  const [coordinators, setCoordinators] = useState([])

  // FUNCTIONS ---------------------------------------------------------------------------------------------

  function updateDate() {
    setDate(moment(`${selectedMonth} ${selectedYear}`, 'MMMM YYYY').toDate())
  }

  function processEvents(data) {
    // Convert activity's times from UTC to local timezone before displaying it on calendar
    let tempData = [];
    data.forEach((activity) => {
      if (activity['is_active']) {
        activity['start_time'] = moment.tz(activity['start_time'], timezone).toDate()
        activity['end_time'] = moment.tz(activity['end_time'], timezone).toDate()
        activity['title_plus_time'] = activity['title'] + ' ' + moment.tz(activity['start_time'], timezone).format('h:mma') + '-' + moment.tz(activity['end_time'], timezone).format('h:mma')
        tempData.push(activity)
      }
    })
    setEvents(tempData)
    setOriginalEvents(tempData)
    setLoading(false)
  }

  const goToActivity = useCallback((activity) => {
    history.push({
      pathname: '/view-one-activity/' + activity.id,
      state: {
        primary_key: activity.id
      }
    })
  },[history])

  useEffect(() => {
    axios
      .get(`${API_URL}/community-coordinators/${localStorage.getItem('community-id')}`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
      .then(
        (response) => {
          let options = []
          for(let i = 0; i < response.data.members.length; i++) {
            let user = response.data.members[i]
            //hack to parse number
            let formattedPhoneNum = formatPhoneNumber("+1" + user.phone_number_1)
            options.push({
              key: user.id,
              label: `${user.first_name} ${user.last_name}`,
              value: user.id,
              email: user.email,
              phone: formattedPhoneNum,
            })
          }
          console.log(options)
          setCoordinators(options)

        },
        (error) => {
          toast.error('Opps, something went wrong!')
          console.log(error)
        }
      )
  }, [token])

  // Check if a volunteer array has a volunteer in it
  function hasVolunteer(member, volunteerArray) {
    for (var i = 0; i < volunteerArray.length; i++) {
      if (volunteerArray[i].first_name === member.first_name && volunteerArray[i].last_name === member.last_name) {
        return true
      }
    }
  }

  // Filter by activity function
  function addSelectedCategories(category, isChecked) {
    var newSelectedCategories = []
    if (isChecked) {
      newSelectedCategories = selectedCategories.concat(category)
      setSelectedCategories(newSelectedCategories)
    } else {
      newSelectedCategories = selectedCategories
      var index = newSelectedCategories.indexOf(category)
      if (index !== -1) {
        newSelectedCategories.splice(index, 1)
        setSelectedCategories(newSelectedCategories)
      }
    }
    var filteredEvents = []
    const splitNames = selectedMember.split(' ')
    var member_object = {
      first_name: splitNames[0],
      last_name: splitNames[1],
    }
    originalEvents.forEach((activity) => {
      if (
        (newSelectedCategories.includes(activity['activity_type']) ||
          newSelectedCategories.length === 0) &&
        (selectedStatuses.includes(activity['activity_status']) ||
          selectedStatuses.length === 0) &&
        (selectedMember === 'All' || hasVolunteer(member_object, activity['volunteers']))
      ) {
        filteredEvents.push(activity)
      }
    })
    setEvents(filteredEvents)
  }

  // Filter by status function
  function addSelectedStatus(status, isChecked) {
    var newSelectedStatuses = []
    if (isChecked) {
      newSelectedStatuses = selectedStatuses.concat(status)
      setSelectedStatuses(newSelectedStatuses)
    } else {
      newSelectedStatuses = selectedStatuses
      var index = newSelectedStatuses.indexOf(status)
      if (index !== -1) {
        newSelectedStatuses.splice(index, 1)
        setSelectedStatuses(newSelectedStatuses)
      }
    }
    var filteredEvents = []
    const splitNames = selectedMember.split(' ')
    var member_object = {
      first_name: splitNames[0],
      last_name: splitNames[1],
    }
    originalEvents.forEach((activity) => {
      if (
        (selectedCategories.includes(activity['activity_type']) ||
          selectedCategories.length === 0) &&
        (newSelectedStatuses.includes(activity['activity_status']) ||
          newSelectedStatuses.length === 0) &&
        (selectedMember === 'All' || hasVolunteer(member_object, activity['volunteers']))
      ) {
        filteredEvents.push(activity)
      }
    })
    setEvents(filteredEvents)
  }

  // Filter by member function
  function filterMember(member) {
    setSelectedMember(member)
    var filteredEvents = []
    const splitNames = member.split(' ')
    var member_object = {
      first_name: splitNames[0],
      last_name: splitNames[1],
    }
    originalEvents.forEach((activity) => {
      if (
        (selectedCategories.includes(activity['activity_type']) ||
          selectedCategories.length === 0) &&
        (selectedStatuses.includes(activity['activity_status']) ||
          selectedStatuses.length === 0) &&
        (member === 'All' || hasVolunteer(member_object, activity['volunteers']))
      ) {

        filteredEvents.push(activity)
      }
    })
    setEvents(filteredEvents)
  }

  // API CALLS ---------------------------------------------------------------------------------------------

  useEffect(() => {
    axios
      .get(`${API_URL}/activities/${localStorage.getItem('community-id')}`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`,
        },
      })
      .then(
        (response) => {
          processEvents(response.data)
        },
        (error) => {
          console.log(error)
        }
      )
  }, [])

  useEffect(() => {
    if (userRole !== USER_ROLE_TYPES.COMM_MEMBER)
    {axios
      .get(`${API_URL}/community-people/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        params: JSON.stringify({
          user: localStorage.getItem('email'),
          community: localStorage.getItem('community-name'),
        }),
      })
      .then(
        (response) => {
          setMembers(members.concat(Array.from(response.data.people)))
        },
        (error) => {
          console.log(error)
        }
      )} else {
        setMembers(members.concat([{ first_name: localStorage.getItem('first-name'), last_name: localStorage.getItem('last-name') }]))
      }
  }, [])

  // Otherwise they will see the entire calendar
  return (
    <div>
      <CommunityNavbar />
      <Container style={containerStyle}>
        <Columns ismultiline={'true'}>
          <Columns.Column size={3}>
            <Box>
              <Heading size={6}>Filter by Status</Heading>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Checkbox
                  style={{ marginRight: '10px' }}
                  onClick={(e) =>
                    addSelectedStatus('Help needed', e.target.checked)
                  }
                />
                <span className='dot-green'></span>Help needed
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Checkbox
                  style={{ marginRight: '10px' }}
                  onClick={(e) =>
                    addSelectedStatus('Needs met', e.target.checked)
                  }
                />
                <span className='dot-blue'></span>Needs met
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Checkbox
                  style={{ marginRight: '10px' }}
                  onClick={(e) =>
                    addSelectedStatus('Occasion', e.target.checked)
                  }
                />
                <span className='dot-orange'></span>Occasion
              </div>
              <hr />
              <Heading size={6} style={{ marginTop: '5%' }}>
                Filter by Member
              </Heading>
              <Field>
                <Control>
                  <Select
                    name='member'
                    value={selectedMember}
                    fullwidth='true'
                    onChange={(e) => filterMember(e.target.value)}
                  >
                    {members.map((m) => (
                      <option key={m.last_name + m.first_name}>
                        {m.first_name} {m.last_name}
                      </option>
                    ))}
                  </Select>
                </Control>
              </Field>
              <hr />
              <Heading size={6} style={{ marginTop: '5%' }}>
                Filter by Activity Type
              </Heading>
              {categories.map((t) => (
                <CheckboxField
                  key={t}
                  text={t}
                  onChange={(e) => addSelectedCategories(t, e.target.checked)}
                />
              ))}
              <hr />
              <CustomSections />
              <hr />
              {coordinators.length !== 0 ? (
                <div>
                  <Heading size={5}>
                    Community Leads
                  </Heading>
                  {coordinators.map((c, index) => (
                    <div style={{ marginBottom: '10px' }} key={index}>
                      <p style={{ fontWeight: 'bold' }}>
                        {c.label}
                      </p>
                      <p>

                        <a
                          href={'mailto:' + c.email}
                          className='has-theme-color'
                        >
                          {c.email}
                        </a>
                      </p>
                      <p>
                        {' '}

                        {c.phone}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </Box>
          </Columns.Column>
          <Columns.Column size={9}>
            <Columns ismultiline={'true'}>
              <Columns.Column size={6}>
                <Heading size={4}>Community Calendar</Heading>
              </Columns.Column>
              <Columns.Column size={3}>
                {Permissions.is_community_coordinator && (
                  <Link to='/create-new-activity' style={{ color: 'white' }}>
                    <Button color='primary' fullwidth={true}>
                      <div>
                        <CalendarIcon
                          size={12}
                          style={{ marginRight: '5px' }}
                        />
                        Create Activity
                      </div>
                    </Button>
                  </Link>
                )}
              </Columns.Column>
              <Columns.Column size={3}>
                {Permissions.is_community_coordinator ?
                (
                  <Link to='/manage-activities' style={{ color: 'white' }}>
                    <Button color='primary' fullwidth={true}>
                        <Layers
                          size={12}
                          style={{ marginRight: '5px' }}
                        />
                        Manage Activities
                    </Button>
                  </Link>
                ) :
                (
                  <Link to='/my-activities' style={{ color: 'white' }}>
                    <Button color='primary' fullwidth={true}>
                        <Layers
                          size={12}
                          style={{ marginRight: '5px' }}
                        />
                        My Activities
                    </Button>
                  </Link>
                )}
              </Columns.Column>
            </Columns>
            <hr style={{ marginTop: 0 }} />
            {loading ?
            <>
            <Spinner message='Checking the Calendar' />
            </>
            :
            <>
                        <Columns>
              <Columns.Column size={9}>
                <Select
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                  style={{ marginRight: '10px' }}
                >
                  {months.map((month) => (
                    <option key={month} value={month}>{month}</option>
                  ))}
                </Select>
                <Select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  style={{ marginRight: '10px' }}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </Select>
                <Button
                  onClick={updateDate}
                  style={{ color: 'white', backgroundColor: '#2C8595' }}
                >
                  <RefreshCw size={12} style={{ marginRight: '5px' }} /> Go
                </Button>
              </Columns.Column>
              {Permissions.is_community_coordinator &&
                <Columns.Column>
                  <Link to='/activity-report' style={{ color: 'white' }}>
                    <Button
                      className='is-primary is-inverted'
                      style={{
                        boxShadow: '1px 1px 3px 2px rgba(0,0,0,0.1)',
                      }}
                      fullwidth={true}
                    >
                        <Clipboard
                          size={12}
                          style={{ marginRight: '5px' }}
                        />
                        Activity Report
                    </Button>
                  </Link>
                </Columns.Column>
              }
            </Columns>
            <div className='rbc-calendar'>
              <Calendar
                events={events}
                localizer={localizer}
                style={{ height: 600 }}
                date={date}
                onNavigate={(date) => setDate(date)}
                startAccessor='start_time'
                endAccessor='end_time'
                allDayAccessor='all_day'
                titleAccessor='title_plus_time'
                popup={true}
                onSelectEvent={(e) => goToActivity(e)}
                eventPropGetter={(event) => ({
                  style: {
                    backgroundColor: event.color,
                  },
                })}
              />
            </div>
            </>
            }

          </Columns.Column>
        </Columns>
      </Container>
    </div>
  )
}
